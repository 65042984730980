import { Tooltip } from "@material-ui/core"
import NameAvatar from "components/custom/nameAvatar"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import UploadModal from "components/GlobalModals.js/UploadModal"
import download from "downloadjs"
import {
  mapAllPropsToLowercase,
  mapObjPropsToLowercase,
} from "helpers/objPropsToLowercaseMapper"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { useDispatch } from "react-redux"
import { ReactTabulator, reactFormatter } from "react-tabulator"
import { toast } from "react-toastify"
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem, updateSelectedAlternatives } from "store/actions"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import AlternativesLocationModal from "../EditProject/Modals/AlternativesLocationModal"
import CreateLookbackModal from "./Modals/CreateLookbackModal"
import HealthTimelineChartsModal from "./Modals/HealthTimelineChartsModal"
import IntakeFinancialChartsModal from "./Modals/IntakeFinancialChartsModal"
import RoadmapChartModal from "./Modals/RoadmapChartModal"
import SelectProjectListModal from "./Modals/SelectProjectListModal"
import ShareProjectListModal from "./Modals/ShareProjectListModal"
import {
  getSelectHtmlFilterValues,
  getSelectedVisibleRows,
} from "utils/tabulatorFilter"
import { exportTableToExcel } from "utils/exportToExcel"
import ChatGPTDrawer from "components/custom/chatGPTDrawer"
import CreateProjectListModal from "./Modals/CreateProjectListModal"
import CopyProjectsModal from "./Modals/CopyProjectsModal"
import AltChatGPTSummaryIconWithModal from "components/custom/altChatGPTSummaryIconWithModal"
import DiscountRateModal from "./Modals/DiscountRateModal"
import BusinessCaseReportModal from "../EditProject/Modals/BusinessCaseReportModal"
import BatchUpdateModal from "./Modals/BatchUpdateModal"
import { enrichWithPhotoBase64, loadUsersAvatars } from "utils/avatar"
import ProjectSummaryModal from "../EditProject/Modals/ProjectSummaryModal"

const ProjectsGrid = ({
  scenarioId,
  configItems,
  toggleConfigModal,
  pageItem,
  scenario,
  props,
  loadWidgets,
  reloadData,
}) => {
  var tabulatorRef = useRef(null)
  const dispatch = useDispatch()
  const currentUser = userService.getLoggedInUser()
  const [showFilters, setShowFilters] = useState(true)
  const [alternatives, setAlternatives] = useState([])
  const [columns, setColumns] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [showBatchDropdown, setShowBatchDropdown] = useState(false)
  const [showListsModal, setShowListsModal] = useState(false)
  const toggleListsModal = () => setShowListsModal(!showListsModal)
  const [showViewListsModal, setShowViewListsModal] = useState(false)
  const toggleViewListsModal = () => setShowViewListsModal(!showViewListsModal)
  const [selectedAlternativeList, setSelectedAlternativeList] = useState(null)
  const [showShareModal, setShowShareModal] = useState(false)
  const toggleShareModal = () => setShowShareModal(!showShareModal)
  const [showLookbackModal, setShowLookbackModal] = useState(false)
  const toggleLookbackModal = () => setShowLookbackModal(!showLookbackModal)
  const [showLocationsModal, setShowLocationsModal] = useState(false)
  const toggleLocationsModal = () => setShowLocationsModal(!showLocationsModal)
  const [showIntakeModal, setShowIntakeModal] = useState(false)
  const toggleIntakeModal = () => setShowIntakeModal(!showIntakeModal)
  const [showHealthModal, setShowHealthModal] = useState(false)
  const toggleHealthModal = () => setShowHealthModal(!showHealthModal)
  const [showReportModal, setShowReportModal] = useState(false)
  const toggleReportModal = () => setShowReportModal(!showReportModal)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [showSearchModal, setShowSearchModal] = useState(false)
  const toggleSearchModal = () => setShowSearchModal(!showSearchModal)
  const [search, setSearch] = useState("")
  const [searchCards, setSearchCards] = useState("")
  const [alternativesSearch, setAlternativesSearch] = useState([])

  const [selectedAlternatives, setSelectedAlternatives] = useState([])
  const {
    show: showUploadAlternativesModal,
    toggle: toggleUploadAlternativesModal,
  } = useModal()
  const { show: showRoadmapModal, toggle: toggleRoadmapModal } = useModal()
  const { show: showChatGptDrawer, toggle: toggleChatGptDrawer } = useModal()
  const { show: showCreateListModal, toggle: toggleCreateListModal } =
    useModal()
  const {
    show: showCopyAlternativesModal,
    toggle: toggleCopyAlternativesModal,
  } = useModal()
  const { show: showDiscountRateModal, toggle: toggleDiscountRateModal } =
    useModal()
  const { show: showBatchUpdateModal, toggle: toggleBatchUpdateModal } =
    useModal()
  const { show: showProjectSummaryModal, toggle: toggleProjectSummaryModal } =
    useModal()
  const [projectSummaryAlternativeId, setProjectSummaryAlternativeId] =
    useState(-1)

  const [elementsCount, setElementsCount] = useState(0)

  // list / card
  const [viewMode, setViewMode] = useState("list")

  useEffect(() => {
    if (
      configItems != undefined &&
      configItems.length > 0 &&
      (scenarioId == 0 || (scenario != null && scenario != undefined))
    ) {
      const params = new URLSearchParams(window.location.search)
      const listId = params.get("list")
      if (listId != null) {
        loadList(listId)
      } else {
        loadData(selectedAlternativeList)
      }
    }
  }, [configItems, scenario])

  useEffect(() => {
    return () => {
      dispatch(updateSelectedAlternatives([]))
    }
  }, [])

  const loadList = async listId => {
    setIsLoading(true)
    let l = await api.getAlternativeLists(currentUser.userID, scenarioId)
    viewList(l.find(x => x.alternativeListID == listId))
  }

  const loadData = async selectedAltListRef => {
    setIsLoading(true)

    // dispatch(
    //   loadPageItem({
    //     userId: currentUser.userID,
    //     scenarioId: scenario?.scenarioID,
    //     alternativeId: 0,
    //     viewName: "projects",
    //   })
    // )

    let altParams = {}
    if (configItems.find(x => x.colParam == "ReviewDate") != undefined) {
      altParams.reviewDate = configItems.find(
        x => x.colParam == "ReviewDate"
      ).configItemValue
    }
    if (
      configItems.find(x => x.colParam == "BRMID" && x.configType == "DDL") !=
      undefined
    ) {
      altParams.bRMID = configItems.find(
        x => x.colParam == "BRMID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "SponsorID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.sponsorID = configItems.find(
        x => x.colParam == "SponsorID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(x => x.colParam == "LDOID" && x.configType == "DDL") !=
      undefined
    ) {
      altParams.lDOID = configItems.find(
        x => x.colParam == "LDOID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(x => x.colParam == "PMID" && x.configType == "DDL") !=
      undefined
    ) {
      altParams.pMID = configItems.find(
        x => x.colParam == "PMID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "DeliveryPOCID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.deliveryPOCID = configItems.find(
        x => x.colParam == "DeliveryPOCID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "SubmitterID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.submitterID = configItems.find(
        x => x.colParam == "SubmitterID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "WorkflowStatusID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.workflowStatusID = configItems.find(
        x => x.colParam == "WorkflowStatusID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "AlternativePhaseID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.alternativePhaseID = configItems.find(
        x => x.colParam == "AlternativePhaseID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "ProjectCategoryID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.projectCategoryID = configItems.find(
        x => x.colParam == "ProjectCategoryID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "InvestmentCategoryID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.investmentCategoryID = configItems.find(
        x => x.colParam == "InvestmentCategoryID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "CompleteID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.completeID = configItems.find(
        x => x.colParam == "CompleteID" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "BCStatePill" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.bCState = configItems.find(
        x => x.colParam == "BCStatePill" && x.configType == "DDL"
      ).configItemValue
    }
    if (
      configItems.find(
        x => x.colParam == "DecisionID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.decisionID = configItems.find(
        x => x.colParam == "DecisionID" && x.configType == "DDL"
      ).configItemValue
    }

    if (
      configItems.find(
        x => x.colParam == "ProgramID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.programID = configItems.find(
        x => x.colParam == "ProgramID" && x.configType == "DDL"
      ).configItemValue
    }

    if (
      configItems.find(
        x => x.colParam == "AdvancedPlanningID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.advancedPlanningID = configItems.find(
        x => x.colParam == "AdvancedPlanningID" && x.configType == "DDL"
      ).configItemValue
    }

    if (
      configItems.find(
        x => x.colParam == "CPICPhaseID" && x.configType == "DDL"
      ) != undefined
    ) {
      altParams.cPICPhaseID = configItems.find(
        x => x.colParam == "CPICPhaseID" && x.configType == "DDL"
      ).configItemValue
    }

    if (
      configItems.find(x => x.colParam == "Version" && x.configType == "DDL") !=
      undefined
    ) {
      altParams.version = configItems.find(
        x => x.colParam == "Version" && x.configType == "DDL"
      ).configItemValue
    }

    if (
      configItems.find(x => x.colParam == "Exclude" && x.configType == "DDL") !=
      undefined
    ) {
      altParams.exclude = configItems.find(
        x => x.colParam == "Exclude" && x.configType == "DDL"
      ).configItemValue
    }

    if (scenarioId != 0) {
      let alts = await api.getAlternativesByParams(
        currentUser.userID,
        scenarioId,
        altParams
      )
      let altsLower = mapAllPropsToLowercase(alts)
      altsLower = await addUserAvatars(altsLower)
      setAlternatives(sortByAlternativeNumDesc(altsLower))
      loadColumns(altsLower, selectedAltListRef)
    } else {
      let alts = await api.getAlternativesByUserParams(
        currentUser.userID,
        altParams
      )
      let altsLower = mapAllPropsToLowercase(alts)
      altsLower = await addUserAvatars(altsLower)
      setAlternatives(sortByAlternativeNumDesc(altsLower))
      loadColumns(altsLower, selectedAltListRef)
    }
    setIsLoading(false)
  }

  const showProjectSummary = altId => {
    setProjectSummaryAlternativeId(altId)
    toggleProjectSummaryModal()
  }

  const addUserAvatars = async alts => {
    let userIds = [
      ...alts.filter(x => x.hasbrmprofilephoto).map(x => x.brmuserid),
      ...alts.filter(x => x.haspmprofilephoto).map(x => x.pmuserid),
      ...alts
        .filter(x => x.hasdeliverypocprofilephoto)
        .map(x => x.deliverypocuserid),
      // ...alts.map(x => x.leadid),
      ...alts.filter(x => x.hasleaduserprofilephoto).map(x => x.leaduserid),
      ...alts.filter(x => x.hassubmitterprofilephoto).map(x => x.submitterid),
    ]
    userIds = userIds.filter(x => x !== undefined && x !== null)
    let usersAndAvatars = await loadUsersAvatars(userIds)
    alts.forEach(a => {
      let brm = usersAndAvatars.find(x => x.userID == a.brmuserid)
      a.brmuserphotobase64 = brm == undefined ? "" : brm.photoBase64
      let pm = usersAndAvatars.find(x => x.userID == a.pmuserid)
      a.pmuserphotobase64 = pm == undefined ? "" : pm.photoBase64
      let deliverypoc = usersAndAvatars.find(
        x => x.userID == a.deliverypocuserid
      )
      a.deliverypocuserphotobase64 =
        deliverypoc == undefined ? "" : deliverypoc.photoBase64
      // let lead = usersAndAvatars.find(x => x.userID == a.leadid)
      // a.leadphotobase64 = lead == undefined ? "" : lead.photoBase64
      let leaduser = usersAndAvatars.find(x => x.userID == a.leaduserid)
      a.leaduserphotobase64 = leaduser == undefined ? "" : leaduser.photoBase64
      let submitter = usersAndAvatars.find(x => x.userID == a.submitterid)
      a.submitterphotobase64 =
        submitter == undefined ? "" : submitter.photoBase64
    })
    return alts
  }

  const getPhotoBase64Prop = colParam => {
    if (colParam == "brmuser") {
      return "brmuserphotobase64"
    }
    if (colParam == "pmuser") {
      return "pmuserphotobase64"
    }
    if (colParam == "deliverypocuser") {
      return "deliverypocuserphotobase64"
    }
    if (colParam == "lead") {
      return "leadphotobase64"
    }
    if (colParam == "leaduser") {
      return "leaduserphotobase64"
    }
    if (colParam == "submitter") {
      return "submitterphotobase64"
    }
    return ""
  }

  const sortByAlternativeNumDesc = alts => {
    return alts.sort((a, b) => {
      const match1 = a.alternativenum.match(/\d+/)
      const match2 = b.alternativenum.match(/\d+/)

      return (
        (match2 && match2.length > 0 ? match2[0] : "0") -
        (match1 && match1.length > 0 ? match1[0] : "0")
      )
    })
  }

  const loadColumns = (altsLower, selectedAltListRef) => {
    let cols = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        cellClick: function (e, cell) {
          cell.getRow().toggleSelect()
        },
        width: 70,
        frozen: true,
      },
      {
        title: "ID",
        field: "alternativenum",
        width: 80,
        headerFilter: "input",
        frozen: true,
        sorter: (a, b) => {
          const match1 = a.match(/\d+/)
          const match2 = b.match(/\d+/)

          return (
            (match1 && match1.length > 0 ? match1[0] : "0") -
            (match2 && match2.length > 0 ? match2[0] : "0")
          )
        },
      },
      {
        title: scenario !== null ? scenario.alternativeName : "Project",
        field: "alternativename",
        minWidth: 400,
        headerFilter: "input",
        frozen: true,
        formatter: (cell, formatterParams, onRendered) => {
          const renderFn = () => {
            const val = cell.getValue()
            const cellEl = cell.getElement()
            if (cellEl) {
              const formatterCell = cellEl.querySelector(".formatterCell")
              if (formatterCell) {
                const CompWithMoreProps = React.cloneElement(
                  <a
                    href="#"
                    onClick={e =>
                      handleNamePress(
                        e,
                        cell.getRow().getData(),
                        selectedAltListRef !== null
                      )
                    }
                  >
                    {val}
                  </a>,
                  { cell }
                )
                createRoot(cellEl.querySelector(".formatterCell")).render(
                  CompWithMoreProps
                )
              }
            }
          }

          onRendered(renderFn)

          setTimeout(() => {
            renderFn()
          }, 0)
          return '<div class="formatterCell"></div>'
        },
      },
      {
        title: "Attributes",
        width: 120,
        field: "scenarioname",
        /*         headerFilter: "select",
        headerFilterParams: { values: ["All", "Baseline", "Revision"] },
        headerFilterFunc: function (
          headerValue,
          rowValue,
          rowData,
          filterParams
        ) {
          return headerValue == "All"
            ? rowData
            : headerValue == "Baseline"
            ? rowData.revisionaltid == 0
            : rowData.revisionaltid > 0
        }, */
        formatter: (cell, formatterParams, onRendered) => {
          const renderFn = () => {
            let bcIdBase64 = btoa(cell.getRow().getData().businesscaseid)
            let txt =
              window.location.href.replace(window.location.pathname, "") +
              "/login.aspx?bc=" +
              bcIdBase64

            let alternativeid = cell.getRow().getData().alternativeid
            let id = cell.getRow().getData().smartsheetid
            let locked = cell.getRow().getData().locked
            let hasdoc = cell.getRow().getData().hasdoc
            let inrevision = cell.getRow().getData().inrevision
            let revisionaltid = cell.getRow().getData().revisionaltid
            const cellEl = cell.getElement()
            if (cellEl) {
              const formatterCell = cellEl.querySelector(".formatterCelllink")
              if (formatterCell) {
                const CompWithMoreProps = React.cloneElement(
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fas fa-link"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(txt)
                        toast.success("Link copied to clipboard")
                      }}
                    ></i>
                    {id != null && id > 0 && (
                      <span
                        className="ssicon"
                        style={{ marginLeft: "5px" }}
                      ></span>
                    )}
                    {locked && (
                      <i
                        className="fas fa-lock"
                        style={{ color: "red", marginLeft: "5px" }}
                      ></i>
                    )}
                    {hasdoc && (
                      <i
                        className="fas fa-paperclip"
                        style={{ marginLeft: "5px" }}
                      ></i>
                    )}
                    {(scenario == null || scenario.projectPlanTabID != 0) && (
                      <i
                        className="mdi mdi-table-headers-eye"
                        style={{ marginLeft: "5px", fontSize: "16px" }}
                        onClick={() => showProjectSummary(alternativeid)}
                      ></i>
                    )}
                    {/* {inrevision && (
                      <i className="baseicon" style={{ marginLeft: "5px" }}></i>
                    )}
                    {revisionaltid > 0 && (
                      <i className="revicon" style={{ marginLeft: "5px" }}></i>
                    )} */}
                    {alternativeid && (
                      <AltChatGPTSummaryIconWithModal
                        alternativeId={alternativeid}
                        style={{ marginLeft: "5px" }}
                      />
                    )}
                  </div>,
                  { cell }
                )
                createRoot(cellEl.querySelector(".formatterCelllink")).render(
                  CompWithMoreProps
                )
              }
            }
          }
          onRendered(renderFn)

          setTimeout(() => {
            renderFn()
          }, 0)
          return '<div class="formatterCelllink"></div>'
        },
      },
    ]

    if (scenarioId != 0 && scenario.showNM == true) {
      //cols.push({
      cols.splice(3, 0, {
        title: "Request Status",
        field: "nmhedstatus",
        minWidth: 150,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
      })
    }

    if (scenarioId != 0 && scenario.alternativeStatusID == 1) {
      //cols.push({
      cols.splice(4, 0, {
        title: "Score Status",
        field: "scorestatus",
        minWidth: 150,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
      })
    }

    if (scenarioId == 0) {
      cols.splice(1, 0, {
        title: "Decision",
        field: "scenarioname",
        minWidth: 250,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
        formatter: (cell, formatterParams, onRendered) => {
          const renderFn = () => {
            const val = cell.getValue()
            const cellEl = cell.getElement()
            if (cellEl) {
              const formatterCell = cellEl.querySelector(".formatterCell")
              if (formatterCell) {
                const CompWithMoreProps = React.cloneElement(
                  <Tooltip title={<h6>{val}</h6>}>
                    <span>
                      <b>{val}</b>
                    </span>
                  </Tooltip>,
                  { cell }
                )
                createRoot(cellEl.querySelector(".formatterCell")).render(
                  CompWithMoreProps
                )
              }
            }
          }
          onRendered(renderFn)

          setTimeout(() => {
            renderFn()
          }, 0)
          return '<div class="formatterCell"></div>'
        },
      })
    }

    configItems
      .filter(x => x.configType == "CB")
      .forEach(ci => {
        if (ci.configItemValue == "1") {
          const colParam = ci.colParam.toLowerCase()
          let col = {
            title: ci.displayName,
            field: colParam == "return" ? "returnamount" : colParam,
            // widthGrow: 1,
            maxWidth: 300,
          }
          col = addFilter(col, altsLower)

          if (
            colParam == "return" ||
            colParam == "cost" ||
            colParam == "costreserve" ||
            colParam == "benefit" ||
            colParam == "roi" ||
            colParam == "irr"
          ) {
            col.hozAlign = "end"
          }

          if (colParam == "program") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue()
            }
          }
          if (
            colParam == "return" ||
            colParam == "cost" ||
            colParam == "costreserve" ||
            colParam == "benefit"
          ) {
            col.formatter = (cell, formatterParams, onRendered) => {
              return currencyFormatter.format(cell.getValue())
            }
          }
          if (colParam == "exclude") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue() == true ? "Excluded" : ""
            }
          }
          if (colParam == "usediscount") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue() == true ? "ON" : "Off"
            }
          }
          if (colParam == "inactive") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue() == true
                ? "<span style='color: green'><b>Complete</b></span>"
                : ""
            }
          }
          if (colParam == "multiyear") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue() == true ? "Multi-Year" : ""
            }
          }
          if (colParam == "limitaccess") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue() == true ? "Limited" : ""
            }
          }
          if (colParam == "nextreviewdt") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue() == null || cell.getValue() == ""
                ? ""
                : moment(cell.getValue()).format("MM/DD/YYYY hh:mm A")
            }
          }
          if (colParam == "decision" || colParam == "bcstatepill") {
            col.formatter = (cell, formatterParams, onRendered) => {
              return cell.getValue()
            }
          }

          if (colParam == "description") {
            col.width = 250
            col.formatter = (cell, formatterParams, onRendered) => {
              const renderFn = () => {
                const val = cell.getValue()
                const cellEl = cell.getElement()
                if (cellEl) {
                  const formatterCell = cellEl.querySelector(".formatterCell")
                  if (formatterCell) {
                    const CompWithMoreProps = React.cloneElement(
                      <Tooltip title={<h6>{val}</h6>}>
                        <span>{val}</span>
                      </Tooltip>,
                      { cell }
                    )
                    createRoot(cellEl.querySelector(".formatterCell")).render(
                      CompWithMoreProps
                    )
                  }
                }
              }

              onRendered(renderFn)

              setTimeout(() => {
                renderFn()
              }, 0)
              return '<div class="formatterCell"></div>'
            }
          }

          if (colParam == "version") {
            col = {
              ...col,
              minWidth: 100,
              frozen: true,
              formatter: (cell, formatterParams, onRendered) => {
                return cell.getValue()
              },
            }
          }

          if (
            colParam == "brmuser" ||
            colParam == "pmuser" ||
            colParam == "deliverypocuser" ||
            colParam == "lead" ||
            colParam == "leaduser" ||
            colParam == "submitter"
          ) {
            addColNameAvatarFormatter(col, colParam)
          }

          cols.push(col)
        }
      })
    let altCol = cols.find(
      x =>
        x.field != undefined &&
        x.field.toLowerCase().trim() == "alternativename"
    )
    let programCol = cols.find(
      x => x.title != undefined && x.title.toLowerCase().trim() == "program"
    )
    if (altCol != undefined && programCol != undefined) {
      cols.splice(cols.indexOf(programCol), 1)
      cols.splice(cols.indexOf(altCol) + 1, 0, programCol)
    }

    let versionCol = cols.find(x => x.field == "version")
    if (altCol !== undefined && versionCol !== undefined) {
      cols.splice(cols.indexOf(versionCol), 1)
      cols.splice(cols.indexOf(altCol) + 1, 0, versionCol)
    }

    if (scenarioId < 0) {
      cols.splice(2, 0, {
        title: "Model",
        field: "scenarioname",
        minWidth: 250,
        headerFilter: "select",
        headerFilterParams: {
          values: true,
          sortValuesList: "asc",
        },
        minWidth: 100,
        frozen: true,
        formatter: (cell, formatterParams, onRendered) => {
          return cell.getValue()
        },
      })
    }

    setColumns(cols)
  }

  const addColNameAvatarFormatter = (col, colParam) => {
    col.formatter = (cell, formatterParams, onRendered) => {
      const renderFn = () => {
        const val = cell.getValue()
        const cellEl = cell.getElement()
        const photoBase64Prop = getPhotoBase64Prop(colParam)
        const photoBase64 =
          photoBase64Prop == "" ? "" : cell.getRow().getData()[photoBase64Prop]
        if (cellEl) {
          const formatterCell = cellEl.querySelector(".formatterCell")
          if (formatterCell) {
            const CompWithMoreProps = React.cloneElement(
              <NameAvatar
                fullName={cell.getRow().getData()[colParam]}
                showName={true}
                base64Image={photoBase64}
              />,
              { cell }
            )
            createRoot(cellEl.querySelector(".formatterCell")).render(
              CompWithMoreProps
            )
          }
        }
      }

      onRendered(renderFn)

      setTimeout(() => {
        renderFn()
      }, 0)
      return '<div class="formatterCell"></div>'
    }
  }

  const addFilter = (col, altsLower) => {
    if (
      col.field == "bcstatepill" ||
      col.field == "investmentcategory" ||
      col.field == "brmuser" ||
      col.field == "pmuser" ||
      col.field == "decision" ||
      col.field == "submitter" ||
      col.field == "workflowstatus" ||
      col.field == "currentphase" ||
      col.field == "lead" ||
      col.field == "leaduser" ||
      col.field == "leaddeliveryorganization" ||
      col.field == "program" ||
      col.field == "projectcategory" ||
      col.field == "sponsoringunit" ||
      col.field == "deliverypocuser" ||
      col.field == "version"
    ) {
      let prop = col.field
      col.headerFilter = "select"
      col.headerFilterFunc = "in"
      col.headerFilterParams = {
        values: getSelectHtmlFilterValues(
          new Set(
            altsLower
              .map(u => u[prop])
              .filter(x => x !== undefined && x !== null && x !== "")
          )
        ),
        sortValuesList: "asc",
        multiselect: true,
      }
    } else if (
      col.field == "usediscount" ||
      col.field == "limitaccess" ||
      col.field == "multiyear" ||
      col.field == "inactive" ||
      col.field == "exclude" ||
      col.field == "fyplanned" ||
      col.field == "fysubmitted" ||
      col.field == "advancedplanning" ||
      col.field == "cpicphase"
    ) {
      col.headerFilter = "select"
      col.headerFilterParams = {
        values: true,
        sortValuesList: "asc",
      }
    }

    return col
  }

  const handleNamePress = (e, alternative, isListSelected) => {
    e && e.preventDefault()
    const location = {
      pathname: `/editProject/${alternative.scenarioid}/${alternative.alternativeid}`,
      state: { fromList: isListSelected },
    }
    props.history.push(location)
  }

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const addToList = async alternativeList => {
    if (alternativeList.listTypeID == 1) {
      //Alternative list
      let selected = [...selectedAlternatives]
      if (selected.length > 0) {
        let tasks = selected.map(x => async () => {
          return await api.createAlternativeListAlternative(
            alternativeList.alternativeListID,
            x.alternativeid
          )
        })
        await Promise.all(tasks.map(t => t()))
        toast.success("Saved successfuly")
      }
    }
    toggleListsModal()
  }

  const removeFromList = async alternativeList => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      let tasks = selected.map(x => async () => {
        return await api.deleteAlternativeListAlternative(
          selectedAlternativeList.alternativeListID,
          x.alternativeid
        )
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Saved successfuly")
      viewList(selectedAlternativeList)
    }
  }

  const viewList = async alternativeList => {
    setSelectedAlternativeList(alternativeList)
    try {
      setIsLoading(true)
      refreshUrl(alternativeList.alternativeListID)
      let alts = await api.getAlternativesByList(
        currentUser.userID,
        scenarioId,
        alternativeList.alternativeListID
      )
      let altsLower = mapAllPropsToLowercase(alts)
      altsLower = await addUserAvatars(altsLower)
      setAlternatives(sortByAlternativeNumDesc(altsLower))
      loadColumns(altsLower, alternativeList)
      setShowViewListsModal(false)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      setShowViewListsModal(false)
    }
  }

  const copyProjects = () => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      toggleCopyAlternativesModal()
      // swalWithConfirmAndCancelButtons
      //   .fire({
      //     title: `Do you want to include scores?`,
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonText: "Yes",
      //     cancelButtonText: "No",
      //     reverseButtons: true,
      //   })
      //   .then(async result => {
      //     let copy = [...selected]
      //     let tasks = copy.map(x => async () => {
      //       return await api.copyAlternative(
      //         x.alternativeid,
      //         currentUser.userID,
      //         result.isConfirmed
      //       )
      //     })
      //     await Promise.all(tasks.map(t => t()))
      //     setSelectedAlternativeList(null)
      //     loadData(null)
      //     toast.success("Copied successfuly")
      //   })
    }
  }

  const deleteProjects = () => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      swalWithConfirmAndCancelButtons
        .fire({
          title: `Are you sure you want to delete the selected items?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then(async result => {
          if (result.isConfirmed) {
            let copy = [...selected]
            let tasks = copy.map(x => async () => {
              return await api.deleteAlternative(x.alternativeid)
            })
            await Promise.all(tasks.map(t => t()))
            setSelectedAlternativeList(null)
            loadData(null)
            toast.success("Deleted successfuly")
          }
        })
    }
  }

  const createLookback = () => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      toggleLookbackModal()
    }
  }

  const saveLookback = async lookback => {
    let selected = [...selectedAlternatives]
    let tasks = selected.map(x => async () => {
      return await api.createLookback(currentUser.userID, {
        ...lookback,
        alternativeID: x.alternativeid,
      })
    })
    await Promise.all(tasks.map(t => t()))
    toast.success("Lookback created successfuly")
    toggleLookbackModal()
  }

  const updateAlternatives = async (prop, value) => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      let alts = await api.getAlternatives(currentUser.userID, scenarioId)
      let tasks = selected.map(x => async () => {
        let alt = alts.find(a => a.alternativeID == x.alternativeid)
        alt[prop] = value
        alt.alternativeName = null
        return await api.updateAlternative(currentUser.userID, alt)
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Updated successfuly")
    }

    loadData(selectedAlternativeList)
  }

  const deleteLookbacks = () => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      swalWithConfirmAndCancelButtons
        .fire({
          title: `Are you sure you want to delete lookbacks for the selected items?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then(async result => {
          if (result.isConfirmed) {
            let copy = [...selected]
            let tasks = copy.map(x => async () => {
              return await api.deleteLookbacksAlternative(
                currentUser.userID,
                x.alternativeid
              )
            })
            await Promise.all(tasks.map(t => t()))
            setSelectedAlternativeList(null)
            loadData(null)
            toast.success("Lookbacks deleted successfuly")
          }
        })
    }
  }

  const clearForecasts = () => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      swalWithConfirmAndCancelButtons
        .fire({
          title: `Are you sure you want to clear forecasts for the selected items?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then(async result => {
          if (result.isConfirmed) {
            let copy = [...selected]
            let tasks = copy.map(x => async () => {
              return await api.clearAlternativeForecast(x.alternativeid)
            })
            await Promise.all(tasks.map(t => t()))
            setSelectedAlternativeList(null)
            loadData(null)
            toast.success("Forecasts cleared successfuly")
          }
        })
    }
  }

  const downloadReport = (options, callback) => {
    let selected = [...selectedAlternatives]
    setIsDownloading(true)
    api
      .downloadBusinessCaseReport({
        alternativeList: selected.map(x => x.alternativeid).join(","),
        options,
      })
      .then(
        blob => {
          setIsDownloading(false)
          download(blob, "BusinessCaseReports.zip")
          callback && callback()
        },
        err => {
          setIsDownloading(false)
          callback && callback()
        }
      )
  }

  const showSelectionAlert = () => {
    swalWithConfirmButton.fire({
      title: `You must select at least one project to run this.`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })
  }

  const searchAlternatives = async () => {
    let alts = await api.getAlternativesSearch(
      currentUser.userID,
      scenarioId,
      search
    )
    if (alts.length == 0) {
      swalWithConfirmButton.fire({
        title: `No projects found for this search`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      setAlternativesSearch(alts)
      setSelectedAlternatives([])
      toggleSearchModal()
    }
  }

  const saveDiscountRate = async discountRate => {
    let selected = [...selectedAlternatives]
    let tasks = selected.map(x => async () => {
      return await api.updateAlternative(currentUser.userID, {
        ...x,
        discount1: discountRate,
      })
    })
    await Promise.all(tasks.map(t => t()))
    toast.success(`Discount rate applied successfully`)
  }

  const toggleLock = async shouldLock => {
    let selected = [...selectedAlternatives]
    if (selected.length > 0) {
      swalWithConfirmAndCancelButtons
        .fire({
          title: `Are you sure you want to ${
            shouldLock ? "lock" : "unlock"
          } the selected project(s)?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then(async result => {
          if (result.isConfirmed) {
            let tasks = selected.map(x => async () => {
              return await api.lockAlternative(
                x.alternativeid,
                shouldLock ? "locked" : "unlocked"
              )
            })
            await Promise.all(tasks.map(t => t()))
            toast.success(`Project(s) ${!shouldLock ? "locked" : "unlocked"}`)
            loadData(selectedAlternativeList)
          }
        })
    } else {
      showSelectionAlert()
    }
  }

  const uploadAlternatives = async file => {
    return await api.uploadAlternatives(currentUser.userID, scenarioId, file)
  }

  const exportTable = () => {
    let colsToExport = columns.filter(
      x => x.field !== undefined && x.title !== "Attributes"
    )
    let visibleRows = tabulatorRef.current.getData("active")
    // .map(r => {
    //   if (r.inrevision) {
    //     r.alternativename += " - Baseline"
    //   }
    //   if (r.revisionaltid > 0) {
    //     r.alternativename += " - Revision"
    //   }

    //   return r
    // })

    exportTableToExcel(
      visibleRows,
      colsToExport.map(c => {
        return { property: c.field, heading: c.title }
      }),
      "Projects.csv"
    )
  }

  const refreshUrl = listId => {
    const nextURL =
      window.location.origin +
      "/projects/" +
      scenarioId +
      (listId !== undefined ? `?list=${listId}` : "")
    const nextTitle = "Definitive PRO"
    const nextState = { additionalInformation: "Definitive PRO" }

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState(nextState, nextTitle, nextURL)
  }

  const deleteAlternativeList = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this list?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteAlternativeList(
            selectedAlternativeList.alternativeListID
          )
          toggleShareModal()
          setSelectedAlternativeList(null)
          loadData(null)
          toast.success("List deleted successfully")
        }
      })
  }

  const resetToDefaults = async () => {
    let list = configItems
      .filter(x => x.configType == "DDL" || x.configType == "Date")
      .map(x => {
        x.isDirty = true
        x.configItemValue = x.defaultValue
        return x
      })

    setIsSubmitting(true)
    let updateTasks = list.map(x => async () => {
      return await api.updateConfigItem(currentUser.userID, scenarioId, {
        ...x,
        scenarioID: scenarioId,
      })
    })
    if (updateTasks.length > 0) {
      await Promise.all(updateTasks.map(t => t()))
    }
    setIsSubmitting(false)
    reloadData()
  }

  const renderAlternativeCard = (alt, idx) => {
    return (
      <Col key={idx} md="3" className="mb-3">
        <div
          className="d-flex-col w-100 h-100 alternative-card jc-space-between"
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
          onClick={() =>
            handleNamePress(null, alt, selectedAlternativeList !== null)
          }
        >
          <div className="d-flex-row w-100 jc-space-between ai-center">
            <h5>
              <b>
                {alt.alternativenum} - {alt.alternativename}
              </b>
            </h5>
            <div className="d-flex-row" style={{ gap: "5px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: alt.version,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: alt.decision,
                }}
              ></div>
            </div>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-col" style={{ width: "50%" }}>
              <div className="d-flex-row ai-center">
                <span>{alt.scorename} </span>
                <div
                  style={{ marginLeft: "10px" }}
                  dangerouslySetInnerHTML={{
                    __html: alt.ratingpill,
                  }}
                ></div>
              </div>

              <span style={{ marginTop: "10px" }}>
                Cost: <b>{currencyFormatter.format(alt.cost)}</b>
              </span>
              <NameAvatar
                fullName={alt.pmuser}
                showName={true}
                base64Image={alt.pmuserphotobase64}
              />
            </div>
            <div className="d-flex-col">
              <div className="d-flex-row ai-center">
                <span>{alt.secondaryscorename} </span>
                <div
                  style={{ marginLeft: "10px" }}
                  dangerouslySetInnerHTML={{
                    __html: alt.secondaryratingpill,
                  }}
                ></div>
              </div>

              <span style={{ marginTop: "10px" }}>
                Benefit: <b>{currencyFormatter.format(alt.benefit)}</b>
              </span>
            </div>
          </div>

          <div className="d-flex-col ai-center jc-center">
            <span>{alt.percentcomplete}%</span>
            <div
              className="d-flex-row"
              style={{
                width: "70%",
                height: "15px",
                border: "1px solid gray",
                borderRadius: "5px",
                background: "lightgray",
              }}
            >
              <div
                style={{
                  height: "15px",
                  background: "green",
                  borderRadius: "5px",
                  width: `${alt.percentcomplete}%`,
                }}
              ></div>
            </div>
            <div className="d-flex-row w-100 jc-space-between">
              <span style={{ marginLeft: "40px" }}>
                {alt.desiredstart &&
                  alt.desiredstart.indexOf("0001") == -1 &&
                  moment(alt.desiredstart).format("MM/DD/YYYY")}
              </span>
              <span span style={{ marginRight: "40px" }}>
                {alt.plannedfinish &&
                  alt.plannedfinish.indexOf("0001") == -1 &&
                  moment(alt.plannedfinish).format("MM/DD/YYYY")}
              </span>
            </div>
          </div>
          <div className="d-flex-row ai-center jc-end">
            <i
              className="fas fa-link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(txt)
                toast.success("Link copied to clipboard")
              }}
            ></i>
            {alt.smartsheetid != null && alt.smartsheetid > 0 && (
              <span className="ssicon" style={{ marginLeft: "5px" }}></span>
            )}
            {alt.locked && (
              <i
                className="fas fa-lock"
                style={{ color: "red", marginLeft: "5px" }}
              ></i>
            )}
            {alt.hasdoc && (
              <i className="fas fa-paperclip" style={{ marginLeft: "5px" }}></i>
            )}
            {alt.alternativeid && (
              <AltChatGPTSummaryIconWithModal
                alternativeId={alt.alternativeid}
                style={{ marginLeft: "5px" }}
              />
            )}
          </div>
        </div>
      </Col>
    )
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      <Row>
        <Col xs={showChatGptDrawer ? 6 : 12} sm={showChatGptDrawer ? 8 : 12}>
          {!isLoading && columns.length > 0 && (
            <>
              <Row style={{ marginBottom: "15px" }}>
                <Col md="6" style={{ display: "flex", flexDirection: "row" }}>
                  {scenarioId > 0 && scenario.lockBit == false && (
                    <button
                      className="btn btn-primary"
                      style={{ alignSelf: "start" }}
                      onClick={() => {
                        props.history.push(`/editProject/${scenarioId}/-1`)
                      }}
                    >
                      <i className="fas fa-plus"></i> Add{" "}
                      {scenario && scenario.alternativeName}
                    </button>
                  )}

                  {scenarioId > 0 &&
                    scenario.lockBit == false &&
                    viewMode == "list" && (
                      <Dropdown
                        direction="start"
                        className="save-user"
                        isOpen={showBatchDropdown}
                        toggle={() => setShowBatchDropdown(!showBatchDropdown)}
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-outline-primary"
                        >
                          Batch Updates <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: "500px", overflowX: "auto" }}
                        >
                          {(selectedAlternativeList == null ||
                            selectedAlternativeList.listTypeID != 2) && (
                            <>
                              {(selectedAlternativeList == null ||
                                selectedAlternativeList.listTypeID == 1) && (
                                <DropdownItem
                                  onClick={() => {
                                    let selected = [...selectedAlternatives]
                                    if (selected.length > 0) {
                                      toggleListsModal()
                                    } else {
                                      showSelectionAlert()
                                    }
                                  }}
                                >
                                  Add{" "}
                                  {scenario &&
                                    scenario.alternativeName.toLowerCase()}
                                  (s) to list
                                </DropdownItem>
                              )}
                              {selectedAlternativeList != null &&
                                selectedAlternativeList.listTypeID == 1 && (
                                  <DropdownItem
                                    onClick={() => {
                                      removeFromList()
                                    }}
                                  >
                                    Remove{" "}
                                    {scenario &&
                                      scenario.alternativeName.toLowerCase()}
                                    (s) from list
                                  </DropdownItem>
                                )}
                            </>
                          )}

                          {scenarioId != 0 &&
                            currentUser.roleID > 0 && ( // Used to have this > 5
                              <DropdownItem
                                onClick={() => {
                                  copyProjects()
                                }}
                              >
                                Copy{" "}
                                {scenario &&
                                  scenario.alternativeName.toLowerCase()}
                                (s)
                              </DropdownItem>
                            )}

                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                deleteProjects()
                              }}
                            >
                              Delete{" "}
                              {scenario &&
                                scenario.alternativeName.toLowerCase()}
                              (s)
                            </DropdownItem>
                          )}

                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                createLookback()
                              }}
                            >
                              Create lookback(s)
                            </DropdownItem>
                          )}
                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                deleteLookbacks()
                              }}
                            >
                              Delete lookback(s)
                            </DropdownItem>
                          )}
                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                clearForecasts()
                              }}
                            >
                              Clear business case(s)
                            </DropdownItem>
                          )}
                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                toggleLock(true)
                              }}
                            >
                              Lock business case(s)
                            </DropdownItem>
                          )}
                          {/*                           {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "Complete")
                              }}
                            >
                              Set business case status(es) to: Complete
                            </DropdownItem>
                          )}

                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "Final")
                              }}
                            >
                              Set business case status(es) to: Final
                            </DropdownItem>
                          )}
                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "In Progress")
                              }}
                            >
                              Set business case status(es) to: In Progress
                            </DropdownItem>
                          )}
                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "In Review")
                              }}
                            >
                              Set business case status(es) to: In Review
                            </DropdownItem>
                          )}
                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "In Revision")
                              }}
                            >
                              Set business case status(es) to: In Revision
                            </DropdownItem>
                          )}
                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "On-Hold")
                              }}
                            >
                              Set business case status(es) to: On-Hold
                            </DropdownItem>
                          )}
                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("bcState", "Pending Start")
                              }}
                            >
                              Set business case status(es) to: Pending Start
                            </DropdownItem>
                          )}

                          {scenarioId > 0 && (
                            <DropdownItem
                              disabled={selectedAlternatives.length == 0}
                              onClick={() => {
                                if (selectedAlternatives.length > 0) {
                                  toggleDiscountRateModal()
                                }
                              }}
                            >
                              Set discount rate
                            </DropdownItem>
                          )} */}

                          {/*                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 0)
                                }}
                              >
                                Set request status(es) to: Draft
                              </DropdownItem>
                            )}

                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 10)
                                }}
                              >
                                Set request status(es) to: Submitted
                              </DropdownItem>
                            )}

                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 15)
                                }}
                              >
                                Set request status(es) to: Review Questions
                              </DropdownItem>
                            )}

                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 20)
                                }}
                              >
                                Set request status(es) to: Post Review Updates
                              </DropdownItem>
                            )}

                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 25)
                                }}
                              >
                                Set request status(es) to: Hearing Questions
                              </DropdownItem>
                            )}

                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 30)
                                }}
                              >
                                Set request status(es) to: Post Hearing Updates
                              </DropdownItem>
                            )}

                          {scenarioId > 0 &&
                            scenario.showNM == true &&
                            currentUser.roleID > 5 && (
                              <DropdownItem
                                onClick={() => {
                                  updateAlternatives("nmhedStatusID", 110)
                                }}
                              >
                                Set request status(es) to: Final
                              </DropdownItem>
                            )} */}

                          {/*                           {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("useDiscount", true)
                              }}
                            >
                              Turn discounting on for business case(s)
                            </DropdownItem>
                          )}
                          {scenarioId > 0 && currentUser.roleID > 0 && (
                            <DropdownItem
                              onClick={() => {
                                updateAlternatives("useDiscount", false)
                              }}
                            >
                              Turn discounting off for business case(s)
                            </DropdownItem>
                          )} */}

                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                toggleLock(false)
                              }}
                            >
                              Unlock business case(s)
                            </DropdownItem>
                          )}

                          {scenarioId != 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                let selected = [...selectedAlternatives]
                                if (selected.length > 0) {
                                  toggleBatchUpdateModal()
                                } else {
                                  showSelectionAlert()
                                }
                              }}
                            >
                              Update Data
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                </Col>
                <Col md="6">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {alternativesSearch.length > 0 && (
                        <button
                          onClick={() => {
                            setAlternativesSearch([])
                            setSelectedAlternatives([])
                          }}
                          className="btn btn-sm btn-outline-primary"
                        >
                          <i className="fas fa-arrow-left"></i> Full List
                        </button>
                      )}
                      {selectedAlternativeList != null && (
                        <>
                          <h5
                            style={{
                              margin: "auto",
                              fontWeight: "800",
                              color: "red",
                            }}
                          >
                            {selectedAlternativeList.alternativeListName}
                          </h5>
                          <button
                            onClick={() => {
                              setSelectedAlternativeList(null)
                              refreshUrl()
                              loadData(null)
                            }}
                            className="btn btn-sm btn-outline-primary save-user"
                          >
                            <i className="fas fa-arrow-left"></i> Exit List
                          </button>
                          {selectedAlternativeList.userID ==
                            currentUser.userID && (
                            <button
                              onClick={toggleShareModal}
                              className="btn btn-sm btn-outline-primary save-user"
                            >
                              <i className="fas fa-cog"></i> Edit / Share List
                            </button>
                          )}
                        </>
                      )}

                      {currentUser.roleID > 100 && (
                        <button
                          onClick={() =>
                            setViewMode(viewMode == "list" ? "card" : "list")
                          }
                          className="btn btn-sm btn-outline-primary save-user"
                        >
                          {viewMode == "list" ? "Show Cards" : "Show List"}
                        </button>
                      )}

                      {viewMode == "list" && (
                        <button
                          onClick={toggleFilters}
                          className="btn btn-sm btn-outline-primary save-user"
                        >
                          {showFilters ? "Hide" : "Show"}{" "}
                          <i className="fas fa-search"></i>{" "}
                          <i className="fas fa-filter"></i>
                        </button>
                      )}

                      <Dropdown
                        direction="start"
                        className="save-user"
                        isOpen={showActionsDropdown}
                        toggle={() =>
                          setShowActionsDropdown(!showActionsDropdown)
                        }
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-outline-primary"
                        >
                          Actions <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: "500px", overflowX: "auto" }}
                        >
                          <DropdownItem
                            onClick={() => {
                              toggleSearchModal()
                            }}
                          >
                            <i className="fas fa-search"></i> Advanced Search
                          </DropdownItem>

                          {scenarioId > 0 && currentUser.useAI && (
                            <DropdownItem
                              className="always-enabled"
                              onClick={toggleChatGptDrawer}
                            >
                              {scenario != null && (
                                <div>
                                  <i className="mdi mdi-brain" /> Ask AI for{" "}
                                  {scenario.alternativeName}
                                </div>
                              )}
                            </DropdownItem>
                          )}

                          {scenarioId > 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                props.history.push(
                                  `/editProjects/${scenarioId}`
                                )
                              }}
                            >
                              <i className="fas fa-edit"></i> Batch Edit
                            </DropdownItem>
                          )}

                          {scenarioId > 0 && selectedAlternativeList == null && (
                            <DropdownItem
                              onClick={() => {
                                toggleConfigModal()
                              }}
                            >
                              <i className="fas fa-cog"></i> Configure View
                            </DropdownItem>
                          )}

                          {scenarioId > 0 && (
                            <DropdownItem
                              onClick={() => {
                                toggleCreateListModal()
                              }}
                            >
                              <i className="far fa-list-alt"></i> Create
                              Filtered List
                            </DropdownItem>
                          )}

                          {currentUser.roleID != 2 && (
                            <DropdownItem
                              onClick={() => {
                                let selected = [...selectedAlternatives]
                                if (selected.length > 30) {
                                  swalWithConfirmButton.fire({
                                    title: `You must select 30 or less items.`,
                                    icon: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: "Ok",
                                  })
                                } else if (selected.length > 0) {
                                  toggleReportModal()
                                } else {
                                  showSelectionAlert()
                                }
                              }}
                            >
                              <i className="mdi mdi-folder-zip"> </i>Download
                              Business Case Reports
                            </DropdownItem>
                          )}

                          <DropdownItem
                            onClick={() => {
                              exportTable()
                            }}
                          >
                            <i className="fas fa-table"></i> Export Table
                          </DropdownItem>





                          {scenarioId > 0 && currentUser.roleID > 5 && (
                            <DropdownItem
                              onClick={() => {
                                toggleUploadAlternativesModal()
                              }}
                            >
                              <i className="fas fa-cloud-upload-alt"></i> Upload{" "}
                              {scenario &&
                                scenario.alternativeName}
                              s
                            </DropdownItem>
                          )}

                          {scenarioId != 0 && (
                            <DropdownItem
                              onClick={() => {
                                let selected = [...selectedAlternatives]
                                if (selected.length > 0) {
                                  toggleHealthModal()
                                } else {
                                  showSelectionAlert()
                                }
                              }}
                            >
                              <i className="fas fa-chart-pie"></i> View Health and
                              Timeline
                            </DropdownItem>
                          )}

                      {scenarioId != 0 && currentUser.roleID != 2 && (
                            <DropdownItem
                              onClick={() => {
                                let selected = [...selectedAlternatives]
                                if (selected.length > 0) {
                                  toggleIntakeModal()
                                } else {
                                  showSelectionAlert()
                                }
                              }}
                            >
                              <i className="fas fa-funnel-dollar"></i> View Intake
                              and Financials
                            </DropdownItem>
                          )}  

                          {scenarioId != 0 && (
                            <DropdownItem
                              onClick={() => {
                                toggleViewListsModal()
                              }}
                            >
                              <i className="far fa-list-alt"></i> View Lists
                            </DropdownItem>
                          )}

                          <DropdownItem
                            onClick={() => {
                              let selected = [...selectedAlternatives]
                              if (selected.length > 0) {
                                toggleLocationsModal()
                              } else {
                                showSelectionAlert()
                              }
                            }}
                          >
                            <i className="fas fa-map-marked-alt"></i> View Map
                          </DropdownItem>

                          {scenarioId > 0 && (
                            <DropdownItem
                              onClick={() => {
                                props.history.push(
                                  `/ResourcePlanning/${scenarioId}`
                                )
                              }}
                            >
                              <i className="fas fa-people-arrows"></i> View Resource Plans
                            </DropdownItem>
                          )} 

                          {scenarioId != 0 && (
                            <DropdownItem
                              onClick={() => {
                                let selected = [...selectedAlternatives]
                                if (selected.length > 0) {
                                  toggleRoadmapModal()
                                } else {
                                  showSelectionAlert()
                                }
                              }}
                            >
                              <i className="fas fa-road"></i> View Roadmap
                            </DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              </Row>

              <div style={{ marginBottom: "0px" }}>
                {pageItem &&
                  pageItem.specialMessage !== "" &&
                  selectedAlternativeList == null && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "left",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5 style={{ color: "red", margin: 0 }}>
                        {pageItem.specialMessage}
                      </h5>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={resetToDefaults}
                        disabled={isSubmitting}
                      >
                        Clear Filter
                      </button>
                    </div>
                  )}
                <Row>
                  <Col>
                    <Label>{elementsCount} Items</Label>
                  </Col>
                </Row>
                {viewMode == "list" && (
                  <>
                    {alternativesSearch.length == 0 && (
                      <ReactTabulator
                        onRef={r => (tabulatorRef.current = r.current)}
                        columns={columns}
                        data={alternatives}
                        options={{
                          pagination: "local",
                          paginationSize:
                            configItems.find(x => x.configType == "Pager") !==
                            undefined
                              ? configItems.find(x => x.configType == "Pager")
                                  .configItemValue ?? 30
                              : 30,
                          paginationSizeSelector: [
                            5, 10, 20, 30, 50, 100, 200, 300,
                          ],
                          layout:
                            columns.length > 6
                              ? "fitDataStretch"
                              : "fitColumns",
                          cssClass: showFilters
                            ? "show-filters"
                            : "hide-filters",
                          maxHeight: "100%",
                        }}
                        events={{
                          rowSelectionChanged: function (data, rows) {
                            let selectedVisibleData =
                              getSelectedVisibleRows(tabulatorRef)

                            setSelectedAlternatives(selectedVisibleData)
                            dispatch(
                              updateSelectedAlternatives(selectedVisibleData)
                            )
                          },
                          dataFiltered: function (data, rows) {
                            setElementsCount(
                              rows == undefined ? 0 : rows.length
                            )
                          },
                        }}
                      />
                    )}
                    {alternativesSearch.length > 0 && (
                      <ReactTabulator
                        onRef={r => (tabulatorRef.current = r.current)}
                        columns={[
                          {
                            formatter: "rowSelection",
                            titleFormatter: "rowSelection",
                            hozAlign: "center",
                            headerSort: false,
                            cellClick: function (e, cell) {
                              cell.getRow().toggleSelect()
                            },
                            width: 70,
                          },
                          {
                            title: "ID",
                            field: "alternativeNum3",
                            width: 80,
                            // headerFilter: "input",
                          },
                          {
                            title: "Project",
                            field: "alternative",
                            minWidth: 400,
                            formatter: (cell, formatterParams, onRendered) => {
                              const renderFn = () => {
                                const val = cell.getValue()
                                const cellEl = cell.getElement()
                                if (cellEl) {
                                  const formatterCell =
                                    cellEl.querySelector(".formatterCell")
                                  if (formatterCell) {
                                    const CompWithMoreProps =
                                      React.cloneElement(
                                        <a
                                          href="#"
                                          onClick={e => {
                                            e && e.preventDefault()

                                            props.history.push(
                                              `/editProject/${scenarioId}/${
                                                cell.getRow().getData()
                                                  .alternativeID
                                              }`
                                            )
                                          }}
                                        >
                                          {val}
                                        </a>,
                                        { cell }
                                      )
                                    createRoot(
                                      cellEl.querySelector(".formatterCell")
                                    ).render(CompWithMoreProps)
                                  }
                                }
                              }

                              onRendered(renderFn)

                              setTimeout(() => {
                                renderFn()
                              }, 0)
                              return '<div class="formatterCell"></div>'
                            },
                          },
                          {
                            title: "Search Results",
                            field: "searchResult",
                            //minWidth: 400,
                            // headerFilter: "input",
                            formatter: (cell, formatterParams, onRendered) => {
                              const val = cell.getValue()

                              return val
                            },
                          },
                        ]}
                        data={alternativesSearch}
                        options={{
                          pagination: "local",
                          paginationSize: 30,
                          paginationSizeSelector: [
                            5, 10, 20, 30, 50, 100, 200, 300,
                          ],
                          layout:
                            columns.length > 6
                              ? "fitDataStretch"
                              : "fitColumns",
                          // cssClass: showFilters ? "show-filters" : "hide-filters",
                          // renderHorizontal:"virtual"
                        }}
                        events={{
                          rowSelectionChanged: function (data, rows) {
                            let selectedVisibleData =
                              tabulatorRef.current.getData("active")

                            setSelectedAlternatives(selectedVisibleData)
                            dispatch(
                              updateSelectedAlternatives(selectedVisibleData)
                            )
                          },
                          dataFiltered: function (data, rows) {
                            setElementsCount(
                              rows == undefined ? 0 : rows.length
                            )
                          },
                        }}
                      />
                    )}
                  </>
                )}
                {viewMode == "card" && (
                  <>
                    <Row className="mb-3">
                      <Col>
                        <div
                          className="form-group"
                          style={{ marginLeft: "10px" }}
                        >
                          <Label>Search</Label>
                          <Input
                            type="text"
                            style={{ width: "200px" }}
                            value={searchCards}
                            onChange={e => setSearchCards(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {alternatives
                        .filter(
                          x =>
                            searchCards == "" ||
                            x.alternativenum.toString().indexOf(searchCards) >
                              -1 ||
                            x.alternativename
                              .toLowerCase()
                              .indexOf(searchCards) > -1
                        )
                        .map(renderAlternativeCard)}
                    </Row>
                  </>
                )}
              </div>
            </>
          )}
        </Col>
        {showChatGptDrawer && (
          <Col xs={6} sm={4}>
            <ChatGPTDrawer
              isOpen={showChatGptDrawer}
              toggle={toggleChatGptDrawer}
              scope="Scenario"
              field="AlternativeList"
              itemId={scenarioId}
            />
          </Col>
        )}
      </Row>

      <SelectProjectListModal
        isOpen={showListsModal}
        toggleModal={toggleListsModal}
        scenarioId={scenarioId}
        saveCallback={addToList}
      />
      <SelectProjectListModal
        isOpen={showViewListsModal}
        toggleModal={toggleViewListsModal}
        scenarioId={scenarioId}
        saveCallback={viewList}
        isViewList={true}
      />
      <ShareProjectListModal
        isOpen={showShareModal}
        toggleModal={toggleShareModal}
        scenarioId={scenarioId}
        setAlternativeList={setSelectedAlternativeList}
        alternativeList={selectedAlternativeList}
        reloadData={loadList}
        deleteAlternativeList={deleteAlternativeList}
      />
      <CreateLookbackModal
        isOpen={showLookbackModal}
        toggleModal={toggleLookbackModal}
        saveCallback={saveLookback}
      />
      <AlternativesLocationModal
        isOpen={showLocationsModal}
        toggleModal={toggleLocationsModal}
        alternatives={selectedAlternatives}
      />
      <IntakeFinancialChartsModal
        isOpen={showIntakeModal}
        toggleModal={toggleIntakeModal}
        scenarioId={scenarioId}
        alternatives={selectedAlternatives}
      />
      <HealthTimelineChartsModal
        isOpen={showHealthModal}
        toggleModal={toggleHealthModal}
        alternatives={selectedAlternatives}
        scenario={scenario}
      />
      <RoadmapChartModal
        isOpen={showRoadmapModal}
        toggleModal={toggleRoadmapModal}
        alternatives={selectedAlternatives}
        scenario={scenario}
      />

      <BusinessCaseReportModal
        onSave={downloadReport}
        scenarioId={scenarioId}
        toggleModal={toggleReportModal}
        isOpen={showReportModal}
      />

      <BatchUpdateModal
        isOpen={showBatchUpdateModal}
        toggleModal={toggleBatchUpdateModal}
        alternatives={selectedAlternatives}
        scenario={scenario}
        reload={() => loadData(null)}
      />

      {/* <Modal
        backdrop="static"
        isOpen={showReportModal}
        size="sm"
        toggle={() => {
          toggleReportModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Business Case Report
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleReportModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Click Download for a zip file of the selected business case reports
          </p>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleReportModal}
          >
            Cancel
          </button>
          {isDownloading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={downloadReport}
            disabled={isDownloading}
          >
            Download
          </button>
        </div>
      </Modal> */}

      <Modal
        backdrop="static"
        isOpen={showSearchModal}
        size="md"
        toggle={() => {
          toggleSearchModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Advanced Search
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleSearchModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-3">
            <Label className="form-label">Search</Label>
            <Input
              name="search"
              type="text"
              placeholder="Enter a search word or phrase."
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleSearchModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={searchAlternatives}
          >
            Search
          </button>
        </div>
      </Modal>
      <UploadModal
        isOpen={showUploadAlternativesModal}
        toggleModal={toggleUploadAlternativesModal}
        text="Upload a file containing projects"
        callback={uploadAlternatives}
        fileType="Alternatives"
        reload={() => {
          loadWidgets()
          loadData(selectedAlternativeList)
        }}
      />

      <CreateProjectListModal
        scenarioId={scenarioId}
        // createCallback={createCallback}
        isOpen={showCreateListModal}
        toggleModal={toggleCreateListModal}
        listTypeId={2}
      />

      <CopyProjectsModal
        isOpen={showCopyAlternativesModal}
        toggleModal={toggleCopyAlternativesModal}
        scenario={scenario}
        selectedAlternatives={selectedAlternatives}
        reload={() => loadData(null)}
      />

      <DiscountRateModal
        isOpen={showDiscountRateModal}
        toggleModal={toggleDiscountRateModal}
        onSave={saveDiscountRate}
      />
      {projectSummaryAlternativeId > 0 && (
        <ProjectSummaryModal
          isOpen={showProjectSummaryModal}
          toggleModal={toggleProjectSummaryModal}
          alternativeId={projectSummaryAlternativeId}
        />
      )}
    </React.Fragment>
  )
}

export default ProjectsGrid
