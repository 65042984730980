import { TextareaAutosize } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { Col, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"

const ScoreDrawer = ({
  activeDrawer,
  alternativeId,
  scoringScenarioId,
  scenario,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [nextAltScore, setNextAltScore] = useState(null)
  const [userScore, setUserScore] = useState(null)
  const [scenarioRatingScale, setScenarioRatingScale] = useState([])

  useEffect(() => {
    if (
      alternativeId != -1 &&
      scoringScenarioId != -1 &&
      activeDrawer == "Score"
    ) {
      loadAlternativeScore(alternativeId)
      loadScenarioRatingScale()
    }
  }, [activeDrawer, alternativeId, scoringScenarioId])

  const loadAlternativeScore = async alternativeId => {
    try {
      setIsLoading(true)
      let altScore = await api.getAltScoreByAltId(
        scoringScenarioId,
        alternativeId,
        0,
        1
      )
      setNextAltScore(altScore)

      let userScore = await loadUserScore(altScore.altScoreID, altScore.levelID)
      setUserScore(userScore[0])
      setIsLoading(false)
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const loadNext = async () => {
    try {
      setIsLoading(true)

      await saveComment()

      let altScore = await api.getNextAltScore(
        nextAltScore.altScoreID,
        nextAltScore.levelID,
        scoringScenarioId,
        "obj"
      )
      setNextAltScore(altScore)

      let userScores = await loadUserScore(
        altScore.altScoreID,
        altScore.levelID
      )
      setUserScore(userScores[0])
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadPrev = async () => {
    try {
      setIsLoading(true)

      await saveComment()

      let altScore = await api.getPrevAltScore(
        nextAltScore.altScoreID,
        nextAltScore.levelID,
        scoringScenarioId,
        "obj"
      )
      setNextAltScore(altScore)

      let userScores = await loadUserScore(
        altScore.altScoreID,
        altScore.levelID
      )
      setUserScore(userScores[0])
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const loadUserScore = async (altScoreId, levelId) => {
    try {
      let score = await api.getAltUserScore(
        altScoreId,
        levelId,
        currentUser.userID
      )
      return score
    } catch (err) {
      console.log(err)
    }
  }

  const loadScenarioRatingScale = async () => {
    try {
      var scenarioRatings = await api.getScenarioInputRatings(scoringScenarioId)
      setScenarioRatingScale(
        scenarioRatings.sort(
          (a, b) => parseFloat(b.inputValue) - parseFloat(a.inputValue)
        )
      )
    } catch (err) {
      console.log(err)
    }
  }

  const onUserScoreClick = scenarioRating => {
    let userScoreCopy = { ...userScore }
    if (
      userScoreCopy.userScore == scenarioRating.inputValue &&
      userScoreCopy.altScoreID == nextAltScore.altScoreID &&
      userScoreCopy.userID == currentUser.userID &&
      userScoreCopy.userScored
    ) {
      userScoreCopy.userScored = false
      api.deleteAltUserScore(
        userScoreCopy.userID,
        userScoreCopy.altScoreID,
        userScoreCopy.levelID
      )
    } else {
      userScoreCopy.userScore = scenarioRating.inputValue
      userScoreCopy.altScoreID = nextAltScore.altScoreID
      userScoreCopy.userScored = true
      userScoreCopy.userID = currentUser.userID
      api.updateAltUserScore(nextAltScore.levelID, { ...userScoreCopy })
    }
    setUserScore(userScoreCopy)
  }

  const saveComment = async () => {
    if (userScore != null && userScore.userScored && userScore.isDirty) {
      await api.updateAltUserScore(nextAltScore.levelID, { ...userScore })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          {isLoading && nextAltScore == null && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}

          {nextAltScore !== null && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {scenario != null && scenario.scenarioID == scoringScenarioId ? (
                <h3>{scenario.scoreName} Scoring</h3>
              ) : (
                <h3>{scenario.secondaryScoreName} Scoring</h3>
              )}
              <br></br>
              <h5>
                <u>Score {nextAltScore.comparison}</u>
              </h5>
              <p className="mb-0" style={{ marginTop: "15px" }}>
                To what degree does
              </p>
              <p className="mb-0" style={{ color: "maroon", textAlign: "center"  }}>
                {nextAltScore.alternativeName}
              </p>
              <p className="mb-0">project contribute to </p>
              <p className="mb-0" style={{ color: "maroon", textAlign: "center" }}>
                {nextAltScore.objMeasName}
              </p>
              {scenarioRatingScale.length > 0 &&
                scenarioRatingScale.map((s, idx) => (
                  <div
                    key={idx}
                    style={{
                      backgroundColor: s.backColor,
                      color: s.foreColor,
                      textAlign: "center",
                      marginTop: idx == 0 ? "20px" : "10px",
                      padding: "5px 20px 5px 20px",
                      width: "80%",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => onUserScoreClick(s)}
                  >
                    {s.ratingName}{" "}
                    {userScore !== null &&
                      userScore.userScored &&
                      userScore.userScore == s.inputValue && (
                        <i className="fas fa-check"></i>
                      )}
                  </div>
                ))}
              {userScore !== null && userScore.userScored && (
                <TextareaAutosize
                  minRows={3}
                  placeholder="Comment..."
                  className="form-control"
                  style={{ width: "80%", marginTop: "15px" }}
                  onChange={e =>
                    setUserScore({
                      ...userScore,
                      comment: e.target.value,
                      isDirty: true,
                    })
                  }
                  value={userScore.comment || ""}
                />
              )}
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignSelf: "center",
                  width: "80%",
                  height: "auto",
                }}
              >
                <button className="btn btn-primary" onClick={loadPrev}>
                  <i className="fas fa-chevron-left"></i> Previous
                </button>

                <button className="btn btn-primary" onClick={loadNext}>
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </div>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ScoreDrawer
