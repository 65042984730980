import React, { useEffect, useState } from "react"
import api from "services/api.service"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useDispatch, useSelector } from "react-redux"
import { changeNavbarParams, loadPageItem } from "store/actions"
import { userService } from "services/user.service"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { toast } from "react-toastify"
import ProjectConstraints from "./ProjectConstraints"
import ProjectDependencies from "./ProjectDependencies"
import GroupConstraints from "./GroupConstraints"
import ObjectiveConstraints from "./ObjectiveConstraints"
import CostVariables from "./CostVariables"
import ResourceConstraints from "./ResourceConstraints"

const Optimizations = props => {
  const scenarioId = props.match.params.scenarioId
  const currentUser = userService.getLoggedInUser()
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [scenario, setScenario] = useState(null)
  const [activeTab, setActiveTab] = useState("ProjectConstraints")
  const [triggerAltConstraintsSave, setTriggerAltConstraintsSave] =
    useState(false)
  const [triggerObjConstraintsSave, setTriggerObjConstraintsSave] =
    useState(false)
  const [triggerResourceConstraintsSave, setTriggerResourceConstraintsSave] =
    useState(false)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "Optimizations",
      })
    )

    loadData()
  }, [])

  const loadData = async () => {
    let scen = await api.getScenario(scenarioId)
    setScenario(scen)
  }

  const save = () => {
    toast.dismiss()

    if (activeTab == "ProjectConstraints") {
      setTriggerAltConstraintsSave(true)
    }

    if (activeTab == "ObjectiveConstraints") {
      setTriggerObjConstraintsSave(true)
    }

    if (activeTab == "ResourceConstraints") {
      setTriggerResourceConstraintsSave(true)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                      }}
                    >
                      <button
                        id="saveOptimizations"
                        type="button"
                        className="btn btn-primary save-user"
                        onClick={save}
                      >
                        <i className="fas fa-save"></i> Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary save-user"
                        // onClick={cancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </Row>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        className={
                          activeTab === "ProjectConstraints" ? "active" : ""
                        }
                        onClick={() => {
                          setActiveTab("ProjectConstraints")
                        }}
                      >
                        Project Constraints
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        className={
                          activeTab === "ProjectDependencies" ? "active" : ""
                        }
                        onClick={() => {
                          setActiveTab("ProjectDependencies")
                        }}
                      >
                        Project Dependencies
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        className={
                          activeTab === "GroupConstraints" ? "active" : ""
                        }
                        onClick={() => {
                          setActiveTab("GroupConstraints")
                        }}
                      >
                        Group Constraints
                      </NavLink>
                    </NavItem>
                    {scenarioId > 0 && (
                      <>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                            className={
                              activeTab === "ObjectiveConstraints"
                                ? "active"
                                : ""
                            }
                            onClick={() => {
                              setActiveTab("ObjectiveConstraints")
                            }}
                          >
                            Objective Constraints
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                            className={
                              activeTab === "CostVariables" ? "active" : ""
                            }
                            onClick={() => {
                              setActiveTab("CostVariables")
                            }}
                          >
                            Cost Variables
                          </NavLink>
                        </NavItem>
                      </>
                    )}
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                        className={
                          activeTab === "ResourceConstraints" ? "active" : ""
                        }
                        onClick={() => {
                          setActiveTab("ResourceConstraints")
                        }}
                      >
                        Resource Constraints
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Row style={{ marginTop: "10px" }}>
                    {scenario !== null && (
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        <TabPane tabId="ProjectConstraints">
                          <ProjectConstraints
                            activeTab={activeTab}
                            scenario={scenario}
                            triggerSave={triggerAltConstraintsSave}
                            setTriggerSave={setTriggerAltConstraintsSave}
                          />
                        </TabPane>
                        <TabPane tabId="ProjectDependencies">
                          <ProjectDependencies
                            activeTab={activeTab}
                            scenario={scenario}
                            // triggerSave={triggerAltConstraintsSave}
                            // setTriggerSave={setTriggerAltConstraintsSave}
                          />
                        </TabPane>
                        <TabPane tabId="GroupConstraints">
                          <GroupConstraints
                            activeTab={activeTab}
                            scenario={scenario}
                            // triggerSave={triggerAltConstraintsSave}
                            // setTriggerSave={setTriggerAltConstraintsSave}
                          />
                        </TabPane>
                        <TabPane tabId="ObjectiveConstraints">
                          <ObjectiveConstraints
                            activeTab={activeTab}
                            scenario={scenario}
                            triggerSave={triggerObjConstraintsSave}
                            setTriggerSave={setTriggerObjConstraintsSave}
                          />
                        </TabPane>
                        <TabPane tabId="CostVariables">
                          <CostVariables
                            activeTab={activeTab}
                            scenario={scenario}
                            // triggerSave={triggerObjConstraintsSave}
                            // setTriggerSave={setTriggerObjConstraintsSave}
                          />
                        </TabPane>
                        <TabPane tabId="ResourceConstraints">
                          <ResourceConstraints
                            activeTab={activeTab}
                            scenario={scenario}
                            triggerSave={triggerResourceConstraintsSave}
                            setTriggerSave={setTriggerResourceConstraintsSave}
                          />
                        </TabPane>
                      </TabContent>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Optimizations
