import { TextareaAutosize, Tooltip } from "@material-ui/core"
import Widgets from "components/Widgets"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  Row,
  Input,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { changeNavbarParams, loadPageItem } from "store/actions"
import { ObjFinAlert } from "services/scenario.service"
import { exportTableToExcel } from "utils/exportToExcel"
import CustomSelect from "components/Form/CustomSelect"

const Decision = props => {
  const scenarioId = props.match.params.scenarioId
  const dispatch = useDispatch()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const { optObjFin } = useSelector(state => ({
    optObjFin: state.optObjFin.optObjFin,
  }))
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [alternatives, setAlternatives] = useState([])
  const [years, setYears] = useState([])
  const [invStatuses, setInvStatuses] = useState([])
  const [decisions, setDecisions] = useState([])
  const [widgets, setWidgets] = useState([])

  const [selectedAlt, setSelectedAlt] = useState(null)
  const [showExcludedFromPlanning, setShowExcludedFromPlanning] =
    useState(false)

  const [showNotesModal, setShowNotesModal] = useState(false)
  const toggleNotesModal = () => {
    if (selectedAlt !== undefined && selectedAlt !== null) {
      let alt = alternatives.find(
        x => x.alternativeID == selectedAlt.alternativeID
      )
      if (alt) {
        onUpdate({ ...alt, decisionNotes: selectedAlt.decisionNotes })
      }
    }
    setShowNotesModal(!showNotesModal)
  }

  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "AnalysisDecision",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        viewName: "AnalysisDecision",
      })
    )
    loadData()
  }, [])

  const loadData = async () => {
    setIsLoading(true)
    loadWidgets()
    let y = await api.getYears(currentUser.userID)
    setYears(y)
    let is = await api.getList(currentUser.userID, "InvestmentStatus")
    setInvStatuses(is)
    let d = await api.getList(currentUser.userID, "Decisions")
    setDecisions(d)
    await loadAlternatives(showExcludedFromPlanning)
    setIsLoading(false)
  }

  const loadWidgets = async () => {
    let w = await api.getWidgetsByView("MakeDecisions", scenarioId)
    setWidgets(w)
  }

  const loadAlternatives = async showExcluded => {
    let { obj, fin } = optObjFin
    let alts = await api.getAlternativesDecision(
      scenarioId,
      obj == null ? 0 : obj.objModelID,
      fin == null ? 0 : fin.finModelID,
      showExcluded
    )

    let enrichedAlts = enrichWithRunningCostAndBenefitFields(alts)
    if (sortBy !== "") {
      setAlternatives(
        sortDirection == "asc"
          ? sortAsc(enrichedAlts, sortBy)
          : sortDesc(enrichedAlts, sortBy)
      )
    } else {
      setAlternatives(enrichedAlts)
    }
  }

  const save = async alts => {
    // console.log(alts.filter(x => x.isDirty))
    let itemsToUpdate = alts.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      toast.success("Projects saved successfuly")
      loadWidgets()
      loadAlternatives(showExcludedFromPlanning)
    }
  }

  const changeAltProp = (alt, prop, value) => {
    let copy = [...alternatives]
    let item = copy.find(x => x.alternativeID == alt.alternativeID)
    item[prop] = value
    if (prop == "decisionID") {
      let dec = decisions.find(x => x.listItemID == value)
      item.decision = dec == undefined ? "" : dec.listItemName
    }
    item.isDirty = true
    setAlternatives(copy)
    showToastChanges()
  }

  const showToastChanges = alts => {
    if (document.getElementById("unsavedChanges") !== null) {
      toast.update("unsavedChanges", {
        render: () => (
          <div>
            <p className="mb-0">
              You have unsaved changes, don't forget to save them.
            </p>
            <button
              className="btn btn-primary"
              style={{ width: "100%" }}
              onClick={() => {
                save(alts)
              }}
            >
              <i className="fas fa-save"></i> Save
            </button>
          </div>
        ),
        type: toast.TYPE.SUCCESS,
        autoClose: false,
        toastId: "unsavedChanges",
      })
    } else {
      toast.success(
        <div>
          <p className="mb-0">
            You have unsaved changes, don't forget to save them.
          </p>
          <button
            className="btn btn-primary"
            style={{ width: "100%" }}
            onClick={() => {
              save(alts)
            }}
          >
            <i className="fas fa-save"></i> Save
          </button>
        </div>,
        {
          toastId: "unsavedChanges",
          autoClose: false,
        }
      )
    }
  }

  const enrichWithRunningCostAndBenefitFields = alts => {
    let copy = [...alts]
    for (let i = 0; i < copy.length; i++) {
      if (i == 0) {
        copy[i].runningCost = copy[i].cost
        copy[i].runningBenefit = copy[i].saaScore3
      } else {
        let prev = copy[i - 1]
        copy[i].runningCost = prev.runningCost + copy[i].cost
        copy[i].runningBenefit = prev.runningBenefit + copy[i].saaScore3
      }
    }
    return copy
  }

  const getSortIcon = prop => {
    let className = ""
    if (sortBy == prop) {
      className =
        sortDirection == "asc" ? "fas fa-chevron-down" : "fas fa-chevron-up"
    } else {
      className = "fas fa-chevron-down"
    }
    return (
      <i
        className={className}
        style={{ padding: "0px", marginLeft: "5px" }}
      ></i>
    )
  }

  const handleSort = prop => {
    let direction = ""
    if (sortBy == prop) {
      direction = sortDirection == "asc" ? "desc" : "asc"
    } else {
      direction = "asc"
      setSortBy(prop)
    }
    setSortDirection(direction)

    setAlternatives(
      direction == "asc"
        ? enrichWithRunningCostAndBenefitFields(sortAsc(alternatives, prop))
        : enrichWithRunningCostAndBenefitFields(sortDesc(alternatives, prop))
    )
  }

  const sortAsc = (data, prop) => {
    function compare(a, b) {
      if (a[prop] < b[prop]) {
        return -1
      }
      if (a[prop] > b[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }
  const sortDesc = (data, prop) => {
    function compare(a, b) {
      if (b[prop] < a[prop]) {
        return -1
      }
      if (b[prop] > a[prop]) {
        return 1
      }
      return 0
    }
    let ret = [...data].sort(compare)
    return ret
  }

  const exportTable = () => {
    const colsToExport = [
      { property: "alternativeNum", heading: "ID" },
      { property: "alternative", heading: "Project" },
      {
        property: "saaScore3",
        heading: "Benefit Score",
        formatter: row => (row.saaScore3 || 0) + "%",
      },
      { property: "runningBenefit", heading: "Running Benefit" },
      { property: "saaRating", heading: "Group Rating" },
      { property: "cost", heading: "Cost" },
      { property: "runningCost", heading: "Running Cost" },
      { property: "fundedAmount", heading: "Funded" },
      { property: "remainingAmount", heading: "Remaining" },
      { property: "dependencies", heading: "Dependencies" },
      { property: "fySubmitted", heading: "FY Submitted" },
      { property: "fyPlanned", heading: "FY Planned" },
      { property: "investmentStatus", heading: "Investment Status" },
      { property: "decision", heading: "Decision" },
      { property: "decisionNotes", heading: "Notes" },
      { property: "highResource", heading: "Resource Risk" },
    ]
    exportTableToExcel(alternatives, colsToExport, "DecisionAnalysis.csv")
  }

  const saveCallback = React.useCallback(async () => {
    let itemsToUpdate = alternatives.filter(x => x.isDirty)
    if (itemsToUpdate.length > 0) {
      let itemsToUpdateTasks = itemsToUpdate.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, x)
      })
      await Promise.all(itemsToUpdateTasks.map(t => t()))
      toast.success("Projects saved successfuly")
      loadWidgets()
      loadAlternatives(showExcludedFromPlanning)
    }
  }, [alternatives])

  useEffect(() => {
    if (alternatives && alternatives.find(x => x.isDirty) !== undefined) {
      showToastChanges(alternatives)
    }
  }, [alternatives])

  const showToastChangesCallback = React.useCallback(() => {
    toast.success(
      <div>
        <p className="mb-0">
          You have unsaved changes, don't forget to save them.
        </p>
        <button
          className="btn btn-primary"
          style={{ width: "100%" }}
          onClick={() => {
            saveCallback()
          }}
        >
          <i className="fas fa-save"></i> Save
        </button>
      </div>,
      {
        toastId: "unsavedChanges",
        autoClose: false,
      }
    )
  }, [saveCallback])

  const onUpdate = React.useCallback(
    alternative => {
      // showToastChangesCallback()
      setAlternatives(prev =>
        prev.map(a =>
          a.alternativeID == alternative.alternativeID
            ? { ...alternative, isDirty: true }
            : a
        )
      )
    },
    [setAlternatives]
  )

  const toggleNotesModalCallback = React.useCallback(
    val => {
      setShowNotesModal(val)
    },
    [setShowNotesModal]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
          />
          <Card>
            <CardBody>
              {isLoading && (
                <Loader
                  type="line-scale-pulse-out"
                  color={colors.primary}
                  style={{ textAlign: "center" }}
                />
              )}
              <Widgets widgets={widgets} marginBottom="20px" />
              <ObjFinAlert optObjFin={optObjFin} />
              {!isLoading && (
                <>
                  <Row>
                    <Col sm="8">
                      <div>
                        <Input
                          id="showExcludedFromPlanning"
                          type="checkbox"
                          className="form-check-input"
                          checked={showExcludedFromPlanning}
                          onClick={() => {
                            let newVal = !showExcludedFromPlanning
                            setShowExcludedFromPlanning(newVal)
                            loadAlternatives(newVal)
                          }}
                        />
                        <Label
                          for="showExcludedFromPlanning"
                          className="form-check-label"
                          style={{ marginLeft: "10px" }}
                        >
                          Show Excluded from Planning Cycle
                        </Label>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <h6>
                          <i
                            className="fas fa-file-excel"
                            style={{ cursor: "pointer" }}
                            onClick={exportTable}
                          ></i>
                          &nbsp;Export
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ overflowY: "auto" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "65vh",
                        }}
                      >
                        <div className="sticky-table-div">
                          <table
                            className="table table-bordered low-padding-table sticky-table"
                            style={{
                              backgroundColor: "white",
                              borderCollapse: "separate",
                              borderSpacing: "0px",
                              // wdith: "2300px"
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  onClick={() => handleSort("alternativeNum")}
                                  style={{
                                    width: "40px",
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "#f2f2f2",
                                    cursor: "pointer",
                                    zIndex: 10,
                                  }}
                                >
                                  ID {getSortIcon("alternativeNum")}
                                </th>
                                <th
                                  onClick={() => handleSort("alternative")}
                                  style={{
                                    minWidth: "250px",
                                    position: "sticky",
                                    left: 40,
                                    backgroundColor: "#f2f2f2",
                                    cursor: "pointer",
                                    zIndex: 10,
                                  }}
                                >
                                  Project {getSortIcon("alternative")}
                                </th>
                                <th
                                  style={{
                                    width: "40px",
                                    position: "sticky",
                                    left: 290,
                                    backgroundColor: "#f2f2f2",
                                    zIndex: 3,
                                    zIndex: 10,
                                  }}
                                >
                                  Version
                                </th>
                                <th
                                  onClick={() => handleSort("saaScore3")}
                                  style={{
                                    width: "180px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Benefit Score {getSortIcon("saaScore3")}
                                </th>
                                <th style={{ width: "70px" }}>
                                  Running Benefit
                                </th>
                                <th style={{ width: "110px" }}>Group Rating</th>
                                <th
                                  onClick={() => handleSort("cost")}
                                  style={{
                                    width: "100px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Cost {getSortIcon("cost")}
                                </th>
                                <th style={{ width: "120px" }}>Running Cost</th>
                                <th style={{ width: "120px" }}>Funded</th>
                                <th style={{ width: "120px" }}>Remaining</th>
                                <th style={{ width: "150px" }}>Dependencies</th>
                                <th style={{ width: "120px" }}>FY Submitted</th>
                                <th style={{ width: "120px" }}>FY Planned</th>
                                <th style={{ width: "180px" }}>
                                  Investment Status
                                </th>
                                <th
                                  onClick={() => handleSort("decision")}
                                  style={{
                                    width: "150px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Decision {getSortIcon("decision")}
                                </th>
                                <th style={{ minWidth: "250px" }}>Notes</th>
                                <th style={{ width: "120px" }}>
                                  Resource Risk
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {alternatives.map((d, idx) => (
                                <TableRow
                                  key={idx}
                                  alternative={d}
                                  onUpdate={onUpdate}
                                  years={years}
                                  decisions={decisions}
                                  invStatuses={invStatuses}
                                  setSelectedAlt={setSelectedAlt}
                                  toggleNotesModal={toggleNotesModalCallback}
                                />
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        backdrop="static"
        isOpen={showNotesModal}
        size="md"
        toggle={() => {
          toggleNotesModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Edit notes
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleNotesModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {selectedAlt != null && (
            <div className="mb-3">
              <Label className="form-label">Notes</Label>
              <TextareaAutosize
                className="form-control"
                minRows={3}
                onChange={e =>
                  changeAltProp(selectedAlt, "decisionNotes", e.target.value)
                }
                value={selectedAlt.decisionNotes}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={toggleNotesModal}>
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const TableRow = React.memo(function TableRow({
  alternative,
  years,
  decisions,
  invStatuses,
  onUpdate,
  setSelectedAlt,
  toggleNotesModal,
}) {
  return (
    <tr key={alternative.alternativeID}>
      <td style={{ zIndex: 9, textAlign: "left" }}>
        {alternative.alternativeNum}
      </td>
      <td
        style={{
          left: 40,
          position: "sticky",
          zIndex: 9,
          background: "white",
          textAlign: "left",
        }}
      >
        <div>
          <Tooltip title={<h6>{alternative.alternative}</h6>}>
            <span>
              <b>{alternative.alternative}</b>{" "}
            </span>
          </Tooltip>
        </div>
      </td>
      <td
        style={{
          left: 290,
          position: "sticky",
          zIndex: 9,
          background: "white",
        }}
      >
        <div
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{
            __html: alternative.version,
          }}
        ></div>
      </td>
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html: alternative.saaScore,
          }}
        ></div>
      </td>
      <td style={{ textAlign: "end" }}>
        {alternative.runningBenefit.toFixed(2)}
      </td>
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html: alternative.saaRating,
          }}
        ></div>
      </td>
      <td style={{ textAlign: "end" }}>
        {currencyFormatter.format(alternative.cost)}
      </td>
      <td style={{ textAlign: "end" }}>
        {currencyFormatter.format(alternative.runningCost)}
      </td>
      <td>
        <CurrencyInput
          className="form-control align-end"
          style={{ padding: "1px", fontSize: "12px" }}
          value={alternative.fundedAmount}
          onValueChange={(value, name, values) => {
            onUpdate({ ...alternative, fundedAmount: values.float })
          }}
          prefix={"$"}
        />
      </td>
      <td style={{ textAlign: "right" }}>
        {currencyFormatter.format(alternative.remainingAmount)}
      </td>
      <td style={{ textAlign: "left" }}>{alternative.dependencies}</td>
      <td>
        <select
          className="form-control form-select select2 mb-xxl-0"
          value={alternative.fySubmitted || 0}
          style={{
            padding: "1px",
            fontSize: "12px",
            width: "80px",
          }}
          onChange={e => {
            onUpdate({ ...alternative, fySubmitted: e.target.value })
          }}
        >
          {years.map((a, idx) => {
            return (
              <option key={idx} value={`${a.listItemName}`}>
                {a.listItemName}
              </option>
            )
          })}
        </select>
      </td>
      <td>
        <select
          className="form-control form-select select2 mb-xxl-0"
          value={alternative.fyPlanned || 0}
          style={{
            padding: "1px",
            fontSize: "12px",
            width: "80px",
          }}
          onChange={e => {
            onUpdate({ ...alternative, fyPlanned: e.target.value })
          }}
        >
          {years.map((a, idx) => {
            return (
              <option key={idx} value={`${a.listItemName}`}>
                {a.listItemName}
              </option>
            )
          })}
        </select>
      </td>
      <td>
        <select
          className="form-control form-select select2 mb-xxl-0"
          value={alternative.investmentStatusID || 0}
          style={{
            padding: "1px",
            fontSize: "12px",
            width: "120px",
          }}
          onChange={e => {
            onUpdate({ ...alternative, investmentStatusID: e.target.value })
          }}
        >
          {invStatuses.map((a, idx) => {
            return (
              <option key={idx} value={`${a.listItemID}`}>
                {a.listItemName}
              </option>
            )
          })}
        </select>
      </td>
      <td>
        <CustomSelect
          label="Decision"
          value={alternative.decisionID}
          onChange={v => onUpdate({ ...alternative, decisionID: v })}
          options={decisions}
          small={true}
        />
        {/* <select
          className="form-control form-select select2 mb-xxl-0"
          value={alternative.decisionID || 0}
          style={{ padding: "1px", fontSize: "12px" }}
          onChange={e => {
            onUpdate({ ...alternative, decisionID: e.target.value })
          }}
        >
          {decisions.map((a, idx) => {
            return (
              <option
                key={idx}
                value={`${a.listItemID}`}
                style={{
                  backgroundColor: !isNullOrEmpty(a.backColor)
                    ? a.backColor
                    : "white",
                  color: !isNullOrEmpty(a.foreColor) ? a.foreColor : "black",
                }}
              >
                {a.listItemName}
              </option>
            )
          })}
        </select> */}
      </td>
      <td style={{ textAlign: "left" }}>
        <span className="one-line-elipsis">
          <i
            className="fas fa-edit"
            style={{
              marginRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedAlt(alternative)
              toggleNotesModal(true)
            }}
          ></i>
          {alternative.decisionNotes}
        </span>
      </td>
      <td>
        <Input
          type="checkbox"
          checked={alternative.highResource}
          onClick={() =>
            onUpdate({
              ...alternative,
              highResource: !alternative.highResource,
            })
          }
        />
      </td>
    </tr>
  )
})

export default Decision
