import { TextareaAutosize } from "@material-ui/core"
import Multiselect from "multiselect-react-dropdown"
import React, { Fragment, useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import Loader from "react-loaders"
import { Col, Label, Row } from "reactstrap"
import api from "services/api.service"
import colors from "utils/colors"
import { currencyFormatter } from "utils/formatters"
import { getColorsByTableName } from "./ForecastSection"
import { TaskTable } from "./Drawer/TaskDrawer"
import { TaskTypes } from "./Modals/EditTaskModal"
import DynamicForm from "components/custom/DynamicForm/View/DynamicForm"

const CostPoolCell = ({ costPoolYear, costPool }) => {
  const year = costPool?.bcCostPoolYears?.find(
    x => x.yearID == costPoolYear?.yearID
  )

  return (
    <React.Fragment>
      {year != undefined && (
        <CurrencyInput
          className="form-control align-end"
          style={{ padding: "1px", fontSize: "12px" }}
          value={year.amount}
          disabled={true}
          prefix={"$"}
        />
      )}
    </React.Fragment>
  )
}

const BenefitRealizationSection = ({
  activeTab,
  scenario,
  alternative,
  alternativeId,
  changeAlternativeProp,
  sectionFormId,
  showToastChangesCallback,
  triggerFormSave,
  setTriggerFormSave,
  props,
}) => {
  const [userOptions, setUserOptions] = useState([])
  const [selectedUserOptions, setSelectedUserOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [costPoolYears, setCostPoolYears] = useState([])
  const [costTable1, setCostTable1] = useState([])
  const [costTable2, setCostTable2] = useState([])
  const [costTable3, setCostTable3] = useState([])
  const [benefitTable1, setBenefitTable1] = useState([])
  const [benefitTable2, setBenefitTable2] = useState([])
  const [benefitTable3, setBenefitTable3] = useState([])

  useEffect(() => {
    if (activeTab == "BenefitRealization") {
      setUserOptions([])
      setSelectedUserOptions([])
      loadData()
    }
  }, [activeTab, alternativeId])

  const loadData = async () => {
    setIsLoading(true)
    let users = await api.getUsersByEnterprise(scenario.enterpriseID)
    setUserOptions(
      users.map(u => {
        return { name: u.firstName + " " + u.lastName, id: u.userID }
      })
    )
    if (alternative.assessorIDs !== null && alternative.assessorIDs !== "") {
      let list = alternative.assessorIDs.split(",")
      let selectedUsers = []
      list.forEach(id => {
        let user = users.find(x => x.userID == id)
        if (user !== undefined) {
          selectedUsers.push({
            name: user.firstName + " " + user.lastName,
            id: user.userID,
          })
        }
      })
      setSelectedUserOptions(selectedUsers)
    }

    await loadCostpools()

    setIsLoading(false)
  }

  const loadCostpools = async () => {
    let costs = await api.getBCCostPools(alternativeId, "cost", true)
    let costWithYears = costs.find(
      x => x.bcCostPoolYears !== null && x.bcCostPoolYears.length > 0
    )
    if (costWithYears !== undefined) {
      setCostPoolYears(
        costWithYears.bcCostPoolYears.map(y => {
          return { yearID: y.yearID }
        })
      )
    }
    let ct1 = []
    let ct2 = []
    let ct3 = []
    for (let i = 0; i < costs.length; i++) {
      if (i == 0) {
        ct1.push(costs[i])
        continue
      }
      if (ct1.filter(x => x.bcCostPoolID < 0).length !== 2) {
        ct1.push(costs[i])
        continue
      } else {
        if (ct2.filter(x => x.bcCostPoolID < 0).length !== 2) {
          ct2.push(costs[i])
          continue
        } else {
          if (ct3.filter(x => x.bcCostPoolID < 0).length !== 2) {
            ct3.push(costs[i])
            continue
          }
        }
      }
    }
    setCostTable1(ct1)
    setCostTable2(ct2)
    setCostTable3(ct3)

    let benefits = await api.getBCCostPools(alternativeId, "benefit", true)
    let bt1 = []
    let bt2 = []
    let bt3 = []
    for (let i = 0; i < benefits.length; i++) {
      if (i == 0) {
        bt1.push(benefits[i])
        continue
      }
      if (bt1.filter(x => x.bcCostPoolID < 0).length !== 2) {
        bt1.push(benefits[i])
        continue
      } else {
        if (bt2.filter(x => x.bcCostPoolID < 0).length !== 2) {
          bt2.push(benefits[i])
          continue
        } else {
          if (bt3.filter(x => x.bcCostPoolID < 0).length !== 2) {
            bt3.push(benefits[i])
            continue
          }
        }
      }
    }
    setBenefitTable1(bt1)
    setBenefitTable2(bt2)
    setBenefitTable3(bt3)
  }

  const getTableRowsToRender = (list, listName) => {
    return (
      <React.Fragment>
        {list.length > 0 &&
          list.map((c, i) => {
            if (i == 0 || c.bcCostPoolID > 0)
              return (
                <tr
                  key={i}
                  style={{
                    backgroundColor:
                      i == 0
                        ? getColorsByTableName(listName).backgroundColor
                        : "inherit",
                    color:
                      i == 0 ? getColorsByTableName(listName).color : "inherit",
                  }}
                >
                  <td
                    style={{
                      verticalAlign: "middle",
                      backgroundColor:
                        getColorsByTableName(listName).backgroundColor,
                      color: getColorsByTableName(listName).color,
                    }}
                  >
                    {c.costPoolName}
                  </td>
                  {costPoolYears.length > 0 &&
                    costPoolYears.map((y, idx) => (
                      <td key={idx}>
                        {i !== 0 && (
                          <CostPoolCell
                            costPool={c}
                            costPoolYear={y}
                            key={idx}
                          />
                        )}
                      </td>
                    ))}

                  <td
                    className="align-end"
                    style={{
                      backgroundColor: i !== 0 ? "lightgray" : "transparent",
                      fontWeight: "bold",
                    }}
                  >
                    {i !== 0 && currencyFormatter.format(c.totalAmount)}
                  </td>
                  {/* <td style={{ padding: "1px" }}>
                    {i == 0 ? (
                      c.notes
                    ) : c.showTextarea ? (
                      <TextareaAutosize
                        className="form-control"
                        onChange={e =>
                          changeCostPoolProp(
                            listName,
                            "notes",
                            e.target.value,
                            c.bcCostPoolID
                          )
                        }
                        value={c.notes}
                        disabled={c.flowThrough == true}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <p
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "600px",
                            marginBottom: "0px",
                          }}
                        >
                          {c.flowThrough == true
                            ? "This is a flow through cost pool. See the Resource Estimate tab. "
                            : ""}{" "}
                          {c.notes}
                        </p>
                      </div>
                    )}
                  </td> */}
                </tr>
              )
          })}
        {list.length > 0 && (
          <tr
            style={{
              backgroundColor: getColorsByTableName(listName).backgroundColor,
              color: getColorsByTableName(listName).color,
              fontWeight: 600,
            }}
          >
            <td>{list[list.length - 1].costPoolName}</td>
            {costPoolYears.length > 0 &&
              costPoolYears.map((y, idx) => (
                <td key={idx} className="align-end">
                  {currencyFormatter.format(getYearTotals(list, y))}
                </td>
              ))}
            <td className="align-end" style={{ fontWeight: "bold" }}>
              {currencyFormatter.format(getTotals(list, "totalAmount"))}
            </td>
            {/* <td></td> */}
          </tr>
        )}
      </React.Fragment>
    )
  }

  const getYearTotals = (table, year) => {
    return table.reduce((acc, item) => {
      const existingYear = item.bcCostPoolYears.find(
        x => x.yearID == year.yearID
      )
      return acc + (existingYear !== undefined ? existingYear.amount : 0)
    }, 0)
  }

  const getTotals = (table, propToSum) => {
    return table.reduce((acc, item) => {
      return acc + item[propToSum]
    }, 0)
  }

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && (
        <Fragment>
          <Row className="mb-3">
            <Col>
              <h5>
                <b>Review Team:</b>
              </h5>
              <p style={{ marginBottom: 0 }}>
                <u>Best Practice:</u> Assemble a review team consisting of
                individuals who were not directly involved in the project's
                execution but have relevant expertise and objectivity.
              </p>
              <p>
                The team should include members from different departments or
                functions to ensure a well-rounded assessment.
              </p>
              <Multiselect
                options={userOptions}
                selectedValues={selectedUserOptions}
                onSelect={values => {
                  changeAlternativeProp(
                    "assessorIDs",
                    values.map(x => x.id).join(",")
                  )
                  setSelectedUserOptions(values)
                }}
                onRemove={values => {
                  changeAlternativeProp(
                    "assessorIDs",
                    values.map(x => x.id).join(",")
                  )
                  setSelectedUserOptions(values)
                }}
                displayValue="name"
                showCheckbox={true}
                placeholder="Select Users ..."
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="12">
              <h5>
                <b>Planned vs. Actuals:</b>
              </h5>
            </Col>
            <Col md="6">
              <div>
                <Label className="form-label">Planned Deliverables</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control always-disabled"
                  value={alternative.deliverables}
                  disabled={true}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label className="form-label">Actual Deliverables</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control"
                  onChange={e =>
                    changeAlternativeProp("actualDeliverables", e.target.value)
                  }
                  value={alternative.actualDeliverables}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="6">
              <div>
                <Label className="form-label">Planned Business Outcomes</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control always-disabled"
                  value={alternative.businessOutcomes}
                  disabled={true}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label className="form-label">Actual Business Outcomes</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control"
                  onChange={e =>
                    changeAlternativeProp(
                      "actualBusinessOutcome",
                      e.target.value
                    )
                  }
                  value={alternative.actualBusinessOutcome}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="6">
              <div>
                <Label className="form-label">Planned Business Value</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control always-disabled"
                  value={alternative.businessValue}
                  disabled={true}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label className="form-label">Actual Business Value</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control"
                  onChange={e =>
                    changeAlternativeProp(
                      "actualBusinessValue",
                      e.target.value
                    )
                  }
                  value={alternative.actualBusinessValue}
                />
              </div>
            </Col>
          </Row>          

          <Row className="mb-3">
            <Col md={12}>
              <Label className="form-label">Planned Financial Benefit</Label>
              {costPoolYears.length > 0 && (
                <table
                  className="table table-bordered lower-padding-table"
                  style={{ backgroundColor: "white", fontSize: "12px" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "18%" }}></th>
                      {costPoolYears.map((y, idx) => (
                        <th
                          key={idx}
                          style={{ width: `${35 / costPoolYears.length}%` }}
                        >
                          FY {y.yearID}
                        </th>
                      ))}

                      <th style={{ width: "7%" }}>Total</th>
                      {/* <th style={{ width: "40%" }}>Notes</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/*                     {getTableRowsToRender(costTable1, "costTable1")}
                    {getTableRowsToRender(costTable2, "costTable2")}
                    {getTableRowsToRender(costTable3, "costTable3")}
                    <tr
                      style={{ backgroundColor: "lightgray", fontWeight: 600 }}
                    >
                      <td>Total Cost</td>
                      {costPoolYears.map((y, idx) => (
                        <td
                          key={idx}
                          className="align-end"
                          style={{ fontWeight: "bold" }}
                        >
                          {currencyFormatter.format(
                            getYearTotals(
                              [...costTable1, ...costTable2, ...costTable3],
                              y
                            )
                          )}
                        </td>
                      ))}
                      <td className="align-end" style={{ fontWeight: "bold" }}>
                        {currencyFormatter.format(
                          [...costTable1, ...costTable2, ...costTable3].reduce(
                            (acc, item) => acc + item.totalAmount,
                            0
                          )
                        )}
                      </td>
                      <td></td>
                    </tr> */}
                    {getTableRowsToRender(benefitTable1, "benefitTable1")}
                    {getTableRowsToRender(benefitTable2, "benefitTable2")}
                    {getTableRowsToRender(benefitTable3, "benefitTable3")}
                    <tr
                      style={{ backgroundColor: "lightgray", fontWeight: 600 }}
                    >
                      <td>Total Benefit</td>
                      {costPoolYears.map((y, idx) => (
                        <td key={idx} className="align-end">
                          {currencyFormatter.format(
                            getYearTotals(
                              [
                                ...benefitTable1,
                                ...benefitTable2,
                                ...benefitTable3,
                              ],
                              y
                            )
                          )}
                        </td>
                      ))}

                      <td className="align-end" style={{ fontWeight: "bold" }}>
                        {currencyFormatter.format(
                          [
                            ...benefitTable1,
                            ...benefitTable2,
                            ...benefitTable3,
                          ].reduce((acc, item) => acc + item.totalAmount, 0)
                        )}
                      </td>
                      {/* <td></td> */}
                    </tr>
                  </tbody>
                </table>
              )}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="12">
              <div>
                <Label className="form-label">Actual Financial Benefit</Label>
                <TextareaAutosize
                  minRows={5}
                  className="form-control"
                  onChange={e =>
                    changeAlternativeProp(
                      "actualFinancialBenefit",
                      e.target.value
                    )
                  }
                  value={alternative.actualFinancialBenefit}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="12">
              <h5>
                <b>Lessons Learned:</b>
              </h5>
            </Col>
            <Col md="6">
              <div>
                <Label className="form-label">Successes</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control"
                  onChange={e =>
                    changeAlternativeProp("successes", e.target.value)
                  }
                  value={alternative.successes}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label className="form-label">Pain Points</Label>
                <TextareaAutosize
                  minRows={3}
                  className="form-control"
                  onChange={e =>
                    changeAlternativeProp("painPoints", e.target.value)
                  }
                  value={alternative.painPoints}
                />
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <TaskTable
              taskTypeId={TaskTypes.feedback}
              alternative={alternative}
              scenarioId={alternative.scenarioID}
              renderAsTable={true}
              //   showWidgets={true}
              props={props}
            />
          </Row>
        </Fragment>
      )}
      {sectionFormId != 0 && activeTab == "BenefitRealization" && (
        <>
          <Row>
            <Col md="12">
              <div className="divider"></div>
              <DynamicForm
                formId={sectionFormId}
                itemId={alternative.alternativeID}
                scenarioId={alternative.scenarioID}
                setTriggerFormSave={setTriggerFormSave}
                triggerFormSave={triggerFormSave}
                showToastChangesCallback={showToastChangesCallback}
              />
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  )
}

export default BenefitRealizationSection
