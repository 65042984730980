import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import { TaskTypes } from "./EditTaskModal"
import NameAvatar from "components/custom/nameAvatar"
import { enrichWithPhotoBase64 } from "utils/avatar"
import isNullOrEmpty from "utils/isNullOrEmpty"
import { currencyFormatter } from "utils/formatters"

const ProjectSummaryModal = ({ isOpen, toggleModal, alternativeId }) => {
  const currentUser = userService.getLoggedInUser()
  const [isLoading, setIsLoading] = useState(false)
  const [alternative, setAlternative] = useState(null)
  const [risks, setRisks] = useState([])
  const [issues, setIssues] = useState([])
  const [keyTasks, setKeyTasks] = useState([])
  const [ganttChartHeight, setGanttChartHeight] = useState(200)

  useEffect(() => {
    if (isOpen) {
      loadData()
    }
  }, [isOpen])

  const loadData = async () => {
    setIsLoading(true)
    let a = await api.getAlternative(alternativeId)
    let scenario = await api.getScenario(a.scenarioID)
    let list = await enrichWithPhotoBase64(
      [a],
      "pmUserID",
      false,
      "pmUserPhotoBase64"
    )
    a = list[0]
    setAlternative(a)

    let r = await api.getGeneralTasks(
      TaskTypes.risk,
      "Alternative",
      alternativeId,
      currentUser.userID
    )
    setRisks(r)
    let i = await api.getGeneralTasks(
      TaskTypes.issue,
      "Alternative",
      alternativeId,
      currentUser.userID
    )
    setIssues(i)
    let t = await api.getKeyTasks(alternativeId)
    t = t.filter(x => x.startDT != null && x.endDT != null)
    setKeyTasks(t)
    if (t.length > 0) {
      setTimeout(() => {
        renderGanttChart(
          t.filter(x => x.startDT != null && x.endDT != null),
          scenario,
          "ganttChartId"
        )
      }, 1000)
    }
    setIsLoading(false)
  }

  const renderGanttChart = (data, scenario, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let dataMap = data.map(x => {
      return {
        id: x.taskID,
        name: x.taskName,
        actualStart: moment(x.startDT).format("MM/DD/yyyy"),
        actualEnd: moment(x.endDT).format("MM/DD/yyyy"),
        progressValue: x.percComplete / 100,
      }
    })

    setGanttChartHeight(data.length == 0 ? 100 : data.length * 25 + 70)

    var treeData = anychart.data.tree(dataMap, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    //var gh = 300
    //ganttDiv.style.height = gh + "px"

    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()

    chart.splitterPosition("230px")
    dgTooltip.useHtml(true)

    dgTooltip.format(
      "<span>" +
        "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
        "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
        "% Complete: {%progress}<br><br>"
    )
    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}<br><br>"
      )

    var header = chart.getTimeline().header()
    var mlevel = header.level(2)

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")

    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "month", count: 1 },
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])

    if (scenario.fyMonth > 6) {
      var header = chart.getTimeline().header()
      header.level(2).format(function () {
        return +this.value + 1
      })
    }

    chart.rowStroke("0.2 #64b5f6")
    chart.columnStroke("0.2 #64b5f6")
    const thisdate = new Date()
    var year = thisdate.getFullYear()
    var month = thisdate.getMonth()
    var day = thisdate.getDate()

    chart.defaultRowHeight(25)
    chart.xScale().fiscalYearStartMonth(scenario.fyMonth)
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    chart.dataGrid(true)
    chart.fitAll()
  }

  const save = async () => {}

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        {!isLoading && alternative != null && (
          <h5 className="modal-title mt-0" id="myModalLabel">
            Project Status for: {alternative.alternativeNum} -{" "}
            {alternative.alternativeName}
          </h5>
        )}

        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {isLoading && (
          <Loader
            type="line-scale-pulse-out"
            color={colors.primary}
            style={{ textAlign: "center" }}
          />
        )}
        {!isLoading && alternative != null && (
          <>
            <Row>
              <Col md="6">
                <ProjectSummary alternative={alternative} />
              </Col>
              <Col md="6">
                <HealthAndStatus alternative={alternative} />
                <Forecast alternative={alternative} />
              </Col>
            </Row>
            {keyTasks.length > 0 && (
              <Row className="mb-3">
                <div
                  className="mb-3"
                  style={{
                    borderColor: "lightgray",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    padding: "5px",
                  }}
                >
                  <Col>
                    <h3>Summary Schedule</h3>
                    <div
                      id="ganttChartId"
                      style={{
                        width: "100%",
                        height: `${ganttChartHeight}px`,
                        //borderStyle: "solid",
                        //borderWidth: "1px",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    ></div>
                  </Col>
                </div>
              </Row>
            )}
            <Row>
              <Col md="12">
                <ProgressAndPlans alternative={alternative} />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <RiskManagement risks={risks} />
              </Col>
              <Col md="6">
                <IssueManagement issues={issues} />
              </Col>
            </Row>
          </>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
        {/* <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button> */}
      </div>
    </Modal>
  )
}

const ProjectSummary = ({ alternative }) => {
  if (alternative == undefined || alternative == null) return null
  return (
    <div
      className="mb-3"
      style={{
        borderColor: "lightgray",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
      }}
    >
      <h3>Governance</h3>
      <table
        className="table table-bordered"
        style={{ backgroundColor: "white" }}
      >
        <tbody>
          <tr>
            <td style={{ width: "230px" }}>
              <b>Project Manager</b>
            </td>
            <td>
              <NameAvatar
                fullName={alternative.pmUser}
                showName={true}
                base64Image={alternative.pmUserPhotoBase64}
              />
            </td>
          </tr>
          <tr>
            <td>
              <b>Sponsoring Business Unit</b>
            </td>
            <td>{alternative.sponsoringUnit}</td>
          </tr>
          <tr>
            <td>
              <b>Lead Delivery Organization</b>
            </td>
            <td>{alternative.leadDeliveryOrganization}</td>
          </tr>
          <tr>
            <td>
              <b>Lead Delivery Organization POC</b>
            </td>
            <td>{alternative.deliveryPOCUser}</td>
          </tr>
          <tr>
            <td>
              <b>Business Case Status</b>
            </td>
            <td>{alternative.bcState}</td>
          </tr>
          <tr>
            <td>
              <b>Planning Stage</b>
            </td>
            <td>{alternative.currentPhase}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <b>Project Description</b>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{alternative.description}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const ProgressAndPlans = ({ alternative }) => {
  if (alternative == undefined || alternative == null) return null
  return (
    <div
      className="mb-3"
      style={{
        borderColor: "lightgray",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
      }}
    >
      <table
        className="table table-bordered"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th style={{ width: "50%" }}>
              <h3>Progress</h3>
            </th>
            <th style={{ width: "50%" }}>
              <h3>Plans</h3>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div
                dangerouslySetInnerHTML={{
                  __html: alternative.projectProgress,
                }}
              ></div>
            </td>
            <td>
              <div
                dangerouslySetInnerHTML={{
                  __html: alternative.projectPlans,
                }}
              ></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const RiskManagement = ({ risks }) => {
  const lowRisksCount = risks.filter(
    x => x.calcResult.indexOf("Low") > -1
  ).length
  const mediumRisksCount = risks.filter(
    x => x.calcResult.indexOf("Medium") > -1
  ).length
  const highRisksCount = risks.filter(
    x => x.calcResult.indexOf("High") > -1
  ).length

  return (
    <div
      className="mb-3"
      style={{
        borderColor: "lightgray",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
      }}
    >
      <h3>Risk Management</h3>
      <div className="d-flex-row ai-center mb-3" style={{ gap: "20px" }}>
        <div style={redBoxStyle}>{highRisksCount}</div>
        <div style={yellowBoxStyle}>{mediumRisksCount}</div>
        <div style={greenBoxStyle}>{lowRisksCount}</div>
      </div>
      <table
        className="table table-bordered"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Probability</th>
            <th>Impact</th>
            <th>Exposure</th>
          </tr>
        </thead>
        <tbody>
          {risks.map((r, idx) => (
            <tr key={idx}>
              <td>{r.taskName}</td>
              <td>{r.taskDescription}</td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.taskProbability,
                  }}
                ></div>
              </td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.taskImpact,
                  }}
                ></div>
              </td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.calcResult,
                  }}
                ></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const IssueManagement = ({ issues }) => {
  const lowIssuesCount = issues.filter(
    x => x.calcResult.indexOf("Low") > -1
  ).length
  const mediumIssuesCount = issues.filter(
    x => x.calcResult.indexOf("Medium") > -1
  ).length
  const highIssuesCount = issues.filter(
    x => x.calcResult.indexOf("High") > -1
  ).length

  return (
    <div
      className="mb-3"
      style={{
        borderColor: "lightgray",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
      }}
    >
      <h3>Issue Management</h3>
      <div className="d-flex-row ai-center mb-3" style={{ gap: "20px" }}>
        <div style={redBoxStyle}>{highIssuesCount}</div>
        <div style={yellowBoxStyle}>{mediumIssuesCount}</div>
        <div style={greenBoxStyle}>{lowIssuesCount}</div>
      </div>
      <table
        className="table table-bordered"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Urgency</th>
            <th>Impact</th>
            <th>Priority</th>
          </tr>
        </thead>
        <tbody>
          {issues.map((r, idx) => (
            <tr key={idx}>
              <td>{r.taskName}</td>
              <td>{r.taskDescription}</td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.taskProbability,
                  }}
                ></div>
              </td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.taskImpact,
                  }}
                ></div>
              </td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: r.calcResult,
                  }}
                ></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const HealthAndStatus = ({ alternative }) => {
  const getStyle = prop => {
    if (
      alternative[prop] == null ||
      alternative[prop] == 0 ||
      alternative[prop] == 3
    )
      return greenBoxStyle
    else if (alternative[prop] == 1) return redBoxStyle
    else return yellowBoxStyle
  }

  const getOverallStyle = () => {
    if (
      alternative.projectScope == 1 ||
      alternative.projectSchedule == 1 ||
      alternative.projectCost == 1 ||
      alternative.projectQuality == 1 ||
      alternative.projectStaffing == 1
    ) {
      return redBoxStyle
    } else if (
      alternative.projectScope == 2 ||
      alternative.projectSchedule == 2 ||
      alternative.projectCost == 2 ||
      alternative.projectQuality == 2 ||
      alternative.projectStaffing == 2
    ) {
      return yellowBoxStyle
    }
    return greenBoxStyle
  }

  if (alternative == undefined || alternative == null) return null
  return (
    <div
      className="mb-3"
      style={{
        borderColor: "lightgray",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
      }}
    >
      <h3>Health and Status</h3>
      <div className="d-flex-row ai-center mb-3" style={{ gap: "10px" }}>
        <div style={getOverallStyle()}>Overall</div>
        <div style={getStyle("projectScope")}>Scope</div>
        <div style={getStyle("projectSchedule")}>Schedule</div>
        <div style={getStyle("projectCost")}>Cost</div>
        <div style={getStyle("projectQuality")}>Quality</div>
        <div style={getStyle("projectStaffing")}>Staffing</div>
      </div>
      <h3>Comments</h3>
      <p>{alternative.projectHealthComments}</p>
    </div>
  )
}

const Forecast = ({ alternative }) => {
  const planSchedule =
    !isNullOrEmpty(alternative.plannedFinish) &&
    !isNullOrEmpty(alternative.desiredStart)
      ? moment(alternative.plannedFinish).diff(
          moment(alternative.desiredStart),
          "days",
          false
        )
      : 0
  const planCost = alternative.plannedProjectCost

  const inceptionSchedule = !isNullOrEmpty(alternative.desiredStart)
    ? moment().diff(moment(alternative.desiredStart), "days", false) + 1
    : 0
  const inceptionCost = alternative.investmentToDate

  const estimateToSchedule = !isNullOrEmpty(alternative.estimatedCompletionDate)
    ? moment(alternative.estimatedCompletionDate).diff(moment(), "days", false)
    : 0
  const estimateToCost = alternative.estimatedCostToComplete

  const estimateAtSchedule =
    !isNullOrEmpty(alternative.estimatedCompletionDate) &&
    !isNullOrEmpty(alternative.desiredStart)
      ? moment(alternative.estimatedCompletionDate).diff(
          moment(alternative.desiredStart),
          "days",
          false
        )
      : 0
  const estimateAtCost =
    alternative.estimatedCostToComplete + alternative.investmentToDate

  const varianceSchedule = estimateAtSchedule - planSchedule
  const varianceCost =
    alternative.estimatedCostToComplete +
    alternative.investmentToDate -
    alternative.plannedProjectCost

  const variancePercSchedule = varianceSchedule / planSchedule
  const variancePerc = varianceCost / alternative.plannedProjectCost

  if (alternative == undefined || alternative == null) return null
  return (
    <div
      className="mb-3"
      style={{
        borderColor: "lightgray",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "5px",
      }}
    >
      <h3>Forecast</h3>
      <table
        className="table table-bordered"
        style={{ backgroundColor: "white" }}
      >
        <thead>
          <tr>
            <th>Status</th>
            <th>Schedule</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Baseline</b>
            </td>
            <td>{planSchedule} days</td>
            <td>{currencyFormatter.format(isNaN(planCost) ? 0 : planCost)}</td>
          </tr>
          <tr>
            <td>
              <b>Inception-to-Date (ITD)</b>
            </td>
            <td>{inceptionSchedule} days</td>
            <td>
              {currencyFormatter.format(
                isNaN(inceptionCost) ? 0 : inceptionCost
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Estimate-to-Complete (ETC)</b>
            </td>
            <td>{estimateToSchedule} days</td>
            <td>
              {currencyFormatter.format(
                isNaN(estimateToCost) ? 0 : estimateToCost
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Estimate-at-Complete (EAC)</b>
            </td>
            <td>{estimateAtSchedule} days</td>
            <td>
              {currencyFormatter.format(
                isNaN(estimateAtCost) ? 0 : estimateAtCost
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Variance-at-Complete (VAC)</b>
            </td>
            <td style={{ color: varianceSchedule <= 0 ? "black" : "red" }}>
              {varianceSchedule} days
            </td>
            <td
              style={{
                color:
                  isNaN(varianceCost) || varianceCost <= 0 ? "black" : "red",
              }}
            >
              {currencyFormatter.format(isNaN(varianceCost) ? 0 : varianceCost)}
            </td>
          </tr>
          <tr>
            <td>
              <b>Variance-at-Complete % (VAC %)</b>
            </td>
            <td style={{ color: variancePercSchedule <= 0 ? "black" : "red" }}>
              {Math.round(variancePercSchedule * 100, 2)}%
            </td>
            <td
              style={{
                color:
                  isNaN(variancePerc) || variancePerc <= 0 ? "black" : "red",
              }}
            >
              {isNaN(variancePerc) ? 0 : Math.round(variancePerc * 100, 2)}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const redBoxStyle = {
  margin: 0,
  fontSize: "14px",
  textAlign: "center",
  backgroundColor: "#FF0000",
  borderRadius: "5px",
  color: "white",
  fontWeight: "bold",
  paddingLeft: "15px",
  paddingRight: "15px",
}

const yellowBoxStyle = {
  margin: 0,
  fontSize: "14px",
  textAlign: "center",
  backgroundColor: "#FFFF00",
  borderRadius: "5px",
  color: "black",
  fontWeight: "bold",
  paddingLeft: "15px",
  paddingRight: "15px",
}
const greenBoxStyle = {
  margin: 0,
  fontSize: "14px",
  textAlign: "center",
  backgroundColor: "green",
  borderRadius: "5px",
  color: "white",
  fontWeight: "bold",
  paddingLeft: "15px",
  paddingRight: "15px",
}

export default ProjectSummaryModal
