import CustomSelect from "components/Form/CustomSelect"
import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useCallback } from "react"
import Loader from "react-loaders"
import { Label, Input, Modal, Row, Col } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import isNullOrEmpty from "utils/isNullOrEmpty"

export const TaskTypes = {
  changeRequest: 1,
  contractTask: 11,
  portfolioMilestone: 21,
  projectMilestone: 31,
  roadmapMilestone: 41,
  projectTask: 51,
  classTask: 52,
  projectAction: 101,
  portfolioAction: 102,
  feedback: 111,
  risk: 71,
  portfolioRisk: 72,
  issue: 81,
  portfolioIssue: 82,
  workflow: 121,
}

const EditTaskModal = ({
  isEditModalVisible,
  toggleEditModal,
  taskToEdit,
  setTaskToEdit,
  alternatives,
  renderAsTable,
  reload,
  flatTaskList, //optional, only for projectTask (gantt)
  setFlatTaskList, //if this is defined, do not call reload, but set the list directly
}) => {
  const currentUser = userService.getLoggedInUser()
  const [taskType, setTaskType] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)

  const [lstPredecessors, setLstPredecessors] = useState([])
  const [taskPredecessorType, setTaskPredecessorType] = useState("")
  const [taskPredecessor, setTaskPredecessor] = useState("")
  const [subTaskSelected, setSubTaskSelected] = useState("")

  const [lstSubtasksOperations, setLstSubtasksOperations] = useState([])
  const [lstPredecessorsToAssign, setLstPredecessorsToAssign] = useState([])

  const lstPredecessorType = [
    { listItemID: "SS", listItemName: "Start to Start" },
    { listItemID: "SF", listItemName: "Start to Finish" },
    { listItemID: "FS", listItemName: "Finish to Start" },
    { listItemID: "FF", listItemName: "Finish to Finish" },
  ]

  useEffect(() => {
    if (
      isEditModalVisible &&
      taskToEdit !== undefined &&
      taskToEdit !== null &&
      taskToEdit.taskTypeID != undefined
    ) {
      setFile(null)
      loadTaskData()
    }
  }, [isEditModalVisible])

  const loadTaskData = async () => {
    setIsLoading(true)

    setLstSubtasksOperations([])

    let tt = await api.getTaskType(
      taskToEdit.taskTypeID,
      currentUser.enterpriseID,
      taskToEdit.scenarioID
    )
    setTaskType(tt)

    if (
      tt.taskTypeFields.length > 0 &&
      tt.taskTypeFields.find(
        x =>
          x.defaultValue !== undefined &&
          x.defaultValue !== null &&
          x.defaultValue !== ""
      )
    ) {
      let updatedTaskToEdit = { ...taskToEdit }
      tt.taskTypeFields
        .filter(
          x =>
            x.defaultValue !== undefined &&
            x.defaultValue !== null &&
            x.defaultValue !== ""
        )
        .forEach(f => {
          const prop = `${f.fieldProperty[0].toLowerCase()}${f.fieldProperty.slice(
            1
          )}`
          if (isNullOrEmpty(updatedTaskToEdit[prop]))
            updatedTaskToEdit[prop] = f.defaultValue
        })
      setTaskToEdit(updatedTaskToEdit)
    }

    if (taskToEdit.taskTypeID == TaskTypes.projectTask) {
      // var predecessors = findTasksAtSameLevel(
      //   taskToEdit.taskNum,
      //   taskToEdit.parentTaskID
      // )
      var predecessors = flatTaskList
        .filter(x => x.taskNum != taskToEdit.taskNum)
        .map(x => {
          return {
            listItemID: x.taskNum,
            listItemName: x.taskNum + " - " + x.taskName,
          }
        })

      setTaskPredecessorType("FS")
      setTaskPredecessor("")

      var list = []
      if (taskToEdit.predTaskIDs != null && taskToEdit.predTaskIDs.length > 0) {
        taskToEdit.predTaskIDs.forEach(predTaskId => {
          let splitter = predTaskId.indexOf("-") > -1 ? "-" : "+"
          let predecessorWithOffset = predTaskId.split(splitter)
          let taskPredecessor = predecessorWithOffset[0].substring(
            0,
            predecessorWithOffset[0].length - 2
          )
          let taskPredecessorType = predecessorWithOffset[0].substring(
            predecessorWithOffset[0].length - 2
          )
          let predTask = flatTaskList.find(x => x.taskNum == taskPredecessor)
          let offset = ""
          if (predecessorWithOffset.length > 1) {
            offset = splitter + predecessorWithOffset[1]
          }

          if (predTask != undefined) {
            list.push({
              task: predTask,
              type: lstPredecessorType.find(
                x => x.listItemID == taskPredecessorType
              ),
              offset: offset,
            })
          }

          //remove from predecessor/subtask list
          predecessors = predecessors.filter(
            x => x.listItemID != taskPredecessor
          )
        })
      }

      setLstPredecessors(predecessors)
      setLstPredecessorsToAssign(list)
    }

    setIsLoading(false)
  }

  const findTasksAtSameLevel = (taskNum, parentTaskID) => {
    if (flatTaskList == undefined) {
      return []
    }

    return flatTaskList
      .filter(t => t.parentTaskID == parentTaskID && t.taskNum != taskNum)
      .map(tsk => {
        return {
          listItemID: tsk.taskNum,
          listItemName: tsk.taskName,
        }
      })
  }

  const saveTaskFunc = async () => {
    if (flatTaskList != undefined) {
      for (const subtask in lstSubtasksOperations) {
        await api.updateTask(currentUser.userID, {
          ...lstSubtasksOperations[subtask],
        })
      }

      taskToEdit.predTaskIDs = lstPredecessorsToAssign.map(x => {
        return (
          x.task.taskNum +
          x.type.listItemID +
          (x.offset != undefined ? x.offset : "")
        )
      })
    }

    if (taskToEdit.taskID > -1) {
      await api.updateTask(currentUser.userID, { ...taskToEdit })
      await handleFileUpload(taskToEdit.taskID)
      toggleEditModal()
      if (setFlatTaskList != undefined) {
        let copy = [...flatTaskList]
        let item = copy.find(x => x.taskID == taskToEdit.taskID)
        copy.splice(copy.indexOf(item), 1, {
          ...taskToEdit,
          Predecessor: taskToEdit.predTaskIDs
            ? taskToEdit.predTaskIDs.map(x => x.trim()).join(",")
            : null,
        })
        console.log("UPDATED")
        console.log(copy)
        setFlatTaskList(copy)
      } else {
        reload && reload()
      }
    } else {
      let taskid = await api.createTask(currentUser.userID, { ...taskToEdit })
      await handleFileUpload(taskid)
      toggleEditModal()
      if (setFlatTaskList != undefined) {
        let updatedTask = await api.getTask(taskid)
        let copy = [...flatTaskList]
        copy.push({
          ...updatedTask,
          Predecessor: updatedTask.predTaskIDs
            ? updatedTask.predTaskIDs.map(x => x.trim()).join(",")
            : null,
        })
        console.log("UPDATED")
        console.log(copy)
        setFlatTaskList(copy)
      } else {
        reload && reload()
      }
    }
  }

  const handleFileUpload = async taskId => {
    if (
      taskId !== undefined &&
      taskId !== null &&
      taskId !== "" &&
      taskId > 0 &&
      file != null
    ) {
      await api.uploadTaskDocument(taskId, file)
    }
  }

  const deleteTask = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `Are you sure you want to delete this?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.deleteTask(currentUser.userID, taskToEdit.taskID)
          toggleEditModal()
          if (setFlatTaskList != undefined) {
            let copy = [...flatTaskList]
            let item = copy.find(x => x.taskID == taskToEdit.taskID)
            copy.splice(copy.indexOf(item), 1)
            setFlatTaskList(copy)
          } else {
            reload && reload()
          }
        }
      })
  }

  const changeTaskProp = (prop, value) => {
    let newValue = value

    if (prop == "subtasks") {
      //add it visually
      var subtask = flatTaskList.filter(x => x.taskNum == value)[0]
      newValue = taskToEdit.subtasks
      newValue.push(subtask)

      //add in operation list
      var subtasks = lstSubtasksOperations
      subtask.parentTaskID = taskToEdit.taskNum
      subtasks.push(subtask)
      setLstSubtasksOperations(subtasks)

      //remove from predecessor/subtask list
      var predecessors = lstPredecessors.filter(x => x.listItemID != value)
      setLstPredecessors(predecessors)

      //clear selection
      setSubTaskSelected("")
    } else if (prop == "Predecessor" || prop == "PredecessorType") {
      newValue = taskToEdit.predTaskIDs

      if (prop == "Predecessor") {
        setTaskPredecessor(value) //lstPredecessors.filter(x => x.taskID == value)[0]);
        var newValueIn = ""
        if (taskPredecessorType != null && value != null)
          newValueIn = value + taskPredecessorType
        else newValueIn = null
      } else if (prop == "PredecessorType") {
        setTaskPredecessorType(value) //lstPredecessorType.filter(x => x.listItemID == value)[0]);

        if (taskPredecessor != null && value != null)
          newValueIn = taskPredecessor + value
        else newValueIn = null

        if (newValueIn != null && newValueIn != "") {
          if (newValue == null) newValue = []

          newValue.push(newValueIn)
        }
      }

      prop = "predTaskIDs"
    }

    setTaskToEdit(prev => {
      return { ...prev, [prop]: newValue }
    })
  }

  const changeTaskPropCallback = useCallback(
    (prop, value) => {
      setTaskToEdit(prev => {
        if (
          prop == "durationDays" &&
          value !== undefined &&
          value !== null &&
          parseInt(value) > 0 &&
          prev.startDT !== undefined &&
          prev.startDT !== null
        ) {
          let end = moment(prev.startDT).add(value, "day")
          return {
            ...prev,
            [prop]: value,
            endDT: end.format("YYYY-MM-DDThh:mm:ss"),
          }
        } else if (
          prop == "startDT" &&
          prev.durationDays !== undefined &&
          prev.durationDays !== null &&
          parseInt(prev.durationDays) > 0
        ) {
          let end = moment(value).add(prev.durationDays, "day")
          return {
            ...prev,
            [prop]: value,
            endDT: end.format("YYYY-MM-DDThh:mm:ss"),
          }
        } else if (
          prop == "endDT" &&
          prev.startDT !== undefined &&
          prev.startDT !== null
        ) {
          let end = moment(value)
          let duration = parseInt(end.diff(moment(prev.startDT), "day")) + 1
          return {
            ...prev,
            [prop]: value,
            durationDays: parseInt(duration),
          }
        } else if (
          prop == "ownerUserID" &&
          !isNullOrEmpty(prev.ownerUserID) &&
          prev.ownerUserID != -1
        ) {
          return {
            ...prev,
            [prop]: value,
            ownerUserChanged: true,
          }
        } else {
          return { ...prev, [prop]: value }
        }
      })
    },
    [setTaskToEdit, taskType]
  )

  const onFileChange = event => {
    let f = event.target.files[0]
    setFile(f)
  }

  const deleteSubTask = subtask => {
    //remove visually
    var newValue = taskToEdit.subtasks.filter(x => x.taskID != subtask.taskID)
    setTaskToEdit(prev => {
      return { ...prev, ["subtasks"]: newValue }
    })

    //add to operation list
    var subtasks = lstSubtasksOperations
    subtask.parentTaskID = taskToEdit.parentTaskID
    subtasks.push(subtask)
    setLstSubtasksOperations(subtasks)

    //add to predecessor/subtask list
    var predecessors = lstPredecessors
    predecessors.push({
      listItemID: subtask.taskNum,
      listItemName: subtask.taskNum + " - " + subtask.taskName,
    })
    setLstPredecessors(predecessors)
  }

  const deletePredecessor = subtask => {
    var list = lstPredecessorsToAssign.filter(
      x => x.task.taskNum != subtask.task.taskNum
    )
    setLstPredecessorsToAssign(list)

    //add to predecessor/subtask list
    var predecessors = lstPredecessors
    predecessors.push({
      listItemID: subtask.task.taskNum,
      listItemName: subtask.task.taskNum + " - " + subtask.task.taskName,
    })
    setLstPredecessors(predecessors)
  }

  const addPredecessor = (taskPredecessor, taskPredecessorType) => {
    if (taskPredecessor != "" && taskPredecessorType != "") {
      var list = lstPredecessorsToAssign
      list.push({
        task: flatTaskList.filter(x => x.taskNum == taskPredecessor)[0],
        type: lstPredecessorType.filter(
          x => x.listItemID == taskPredecessorType
        )[0],
      })
      setLstPredecessorsToAssign(list)

      //remove from predecessor/subtask list
      var predecessors = lstPredecessors.filter(
        x => x.listItemID != taskPredecessor
      )
      setLstPredecessors(predecessors)

      //clear selection
      setTaskPredecessor("")
      setTaskPredecessorType("FS")
    }
  }

  const addPredecessorHandler = () => {
    addPredecessor(taskPredecessor, taskPredecessorType)
  }

  return (
    <Modal
      backdrop="static"
      isOpen={isEditModalVisible}
      size={taskType == null ? "lg" : taskType.modalSize}
      toggle={() => {
        toggleEditModal()
      }}
    >
      {taskToEdit !== undefined && taskToEdit !== null && (
        <>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {taskToEdit.taskID == -1 ? "Add " : "Edit "}{" "}
              {taskType != null && taskType.taskTypeName}
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleEditModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {isLoading && (
            <Loader
              type="line-scale-pulse-out"
              color={colors.primary}
              style={{ textAlign: "center" }}
            />
          )}

          {taskType != null && (
            <div className="modal-body">
              <TaskForm
                numColumns={taskType.numColumns}
                fields={taskType.taskTypeFields}
                taskToEdit={taskToEdit}
                changeTaskProp={changeTaskPropCallback}
              />
              {taskType.docAllowed && (
                <>
                  {taskToEdit.taskDocPath !== undefined &&
                    taskToEdit.taskDocPath !== null &&
                    taskToEdit.taskDocPath !== "" &&
                    file == null && (
                      <a
                        href={taskToEdit.taskDocPath}
                        download=""
                        target="_blank"
                        rel="noreferrer"
                      >
                        {
                          taskToEdit.taskDocPath.split("/")[
                            taskToEdit.taskDocPath.split("/").length - 1
                          ]
                        }
                      </a>
                    )}
                  <div className="mb-3">
                    <Label
                      className="btn btn-primary"
                      style={{ marginBottom: "0px", fontWeight: "bold" }}
                    >
                      <input
                        type="file"
                        name="myFile"
                        onChange={onFileChange}
                        style={{ display: "none" }}
                      />
                      Upload Document
                    </Label>
                    {file != null && (
                      <span style={{ marginLeft: "10px" }}>{file.name}</span>
                    )}
                  </div>
                </>
              )}
              {taskToEdit.taskTypeID == TaskTypes.projectTask && (
                <>
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Parent Task</Label>
                        <CustomSelect
                          label="Parent"
                          defaultValue={0}
                          value={taskToEdit.parentTaskID}
                          onChange={v => {
                            changeTaskProp("parentTaskID", v)
                            // var predecessors = findTasksAtSameLevel(
                            //   taskToEdit.taskNum,
                            //   v
                            // )
                            // setLstPredecessors(predecessors)
                          }}
                          options={flatTaskList
                            .filter(x => x.taskID !== taskToEdit.taskID)
                            .map(x => {
                              return {
                                listItemID: x.taskNum,
                                listItemName: x.taskNum + " - " + x.taskName,
                              }
                            })}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label">Predecessor</Label>
                            <CustomSelect
                              label="Predecessor"
                              value={taskPredecessor}
                              onChange={v => changeTaskProp("Predecessor", v)}
                              options={lstPredecessors}
                            />
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Predecessor Type
                            </Label>
                            <CustomSelect
                              label="Predecessor Type"
                              value={taskPredecessorType}
                              onChange={v =>
                                changeTaskProp("PredecessorType", v)
                              }
                              options={lstPredecessorType}
                            />
                          </div>
                        </Col>

                        <Col
                          md={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={addPredecessorHandler}
                            >
                              Add
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">Predecessor List</Label>
                        <table
                          className="table table-bordered low-padding-table"
                          style={{ backgroundColor: "white" }}
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th>Type</th>
                              <th>Offset</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {lstPredecessorsToAssign.map((element, index) => (
                              <tr key={index}>
                                <td>
                                  {element.task.taskNum} -{" "}
                                  {element.task.taskName}{" "}
                                </td>
                                <td>{element.type.listItemName} </td>
                                <td>{element.offset}</td>
                                {/* <input type="text" value={element.offset} /> */}
                                <td>
                                  <i
                                    className="fas fa-trash"
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => deletePredecessor(element)}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* <ul>
                          {lstPredecessorsToAssign.map((element, index) => (
                            <li key={index}>
                              <span>{element.task.taskName} </span> -&nbsp;
                              <span>{element.type.listItemName} </span>
                              <span>{element.offset}</span>
                              <i
                                className="fas fa-trash"
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft: "30px",
                                }}
                                onClick={() => deletePredecessor(element)}
                              ></i>
                            </li>
                          ))}
                        </ul> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">Subtasks</Label>
                        <CustomSelect
                          label="Subtask"
                          value={subTaskSelected}
                          onChange={v => changeTaskProp("subtasks", v)}
                          options={lstPredecessors}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">Subtasks List</Label>
                        <ul>
                          {taskToEdit.subtasks &&
                            taskToEdit.subtasks.map((element, index) => (
                              <li key={index}>
                                <span>{element.taskName} </span>
                                <i
                                  className="fas fa-trash"
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    marginLeft: "30px",
                                  }}
                                  onClick={() => deleteSubTask(element)}
                                ></i>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
        </>
      )}
      <div
        className="modal-footer"
        style={{
          justifyContent:
            taskToEdit !== null && taskToEdit.taskID > -1
              ? "space-between"
              : "flex-end",
        }}
      >
        {taskToEdit !== null &&
          taskToEdit.taskID > -1 &&
          taskToEdit.userEditRight != "readonly" && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteTask}
            >
              Delete
            </button>
          )}
        <div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleEditModal}
          >
            Cancel
          </button>
          {taskToEdit !== null && taskToEdit.userEditRight != "readonly" && (
            <button
              type="button"
              className="btn btn-primary save-user"
              onClick={saveTaskFunc}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

const TaskForm = ({ numColumns, fields, taskToEdit, changeTaskProp }) => {
  return (
    <Row>
      {fields.map((f, idx) => {
        const prop = `${f.fieldProperty[0].toLowerCase()}${f.fieldProperty.slice(
          1
        )}`
        const disabled =
          f.fieldType == "ReadOnly" || taskToEdit.userEditRight == "readonly"
        return (
          <ItemRenderer
            key={idx}
            label={f.fieldLabel}
            value={taskToEdit[prop]}
            changeTaskProp={changeTaskProp}
            prop={prop}
            disabled={disabled}
            options={f.listItems}
            type={f.fieldType}
            col={f.fullRow ? 12 : 12 / numColumns}
            placeholder={f.placeholder}
          />
        )
      })}
    </Row>
  )
}

const ItemRenderer = React.memo(function itemRenderer({
  label,
  value,
  prop,
  changeTaskProp,
  disabled,
  options,
  type,
  col = 6,
  placeholder,
}) {
  return (
    <Col md={col}>
      {type == "Checkbox" && (
        <div className="form-check mb-3">
          <Input
            type="checkbox"
            className="form-check-input"
            checked={value == "1" || value == true}
            onClick={e => {
              changeTaskProp(
                prop,
                value == "0" || value == false ? true : false
              )
            }}
          />
          <Label className="form-check-label">{label}</Label>
        </div>
      )}
      {type != "Checkbox" && (
        <div className="mb-3">
          <Label className="form-label">{label}</Label>
          {type == "AutoNum" && <Label className="form-label">{value}</Label>}
          {type == "ReadOnly" && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: value }} />
            </div>
          )}
          {type == "ReadOnlyDate" && (
            <div>{moment(value).format("MM/DD/YYYY")}</div>
          )}
          {type == "DDL" && (
            <CustomSelect
              label={label}
              value={value}
              onChange={v => changeTaskProp(prop, v)}
              disabled={disabled}
              options={options}
            />
          )}
          {type == "Date" && (
            <Input
              type="date"
              disabled={disabled}
              onChange={e => {
                changeTaskProp(
                  prop,
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }}
              value={moment(value).format("YYYY-MM-DD")}
            />
          )}
          {type == "Duration" && (
            <Input
              type="number"
              disabled={disabled}
              onChange={e => {
                changeTaskProp(prop, e.target.value)
              }}
              value={value}
            />
          )}
          {type == "LongText" && (
            <Input
              type="textarea"
              rows={3}
              onChange={e => changeTaskProp(prop, e.target.value)}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
          {(type == "ShortText" || type == "number") && (
            <Input
              disabled={disabled}
              type="type"
              onChange={e => changeTaskProp(prop, e.target.value)}
              value={value}
              placeholder={placeholder}
            />
          )}
        </div>
      )}
    </Col>
  )
})

export default EditTaskModal
